<template>
  <v-row dense>
      <v-col cols="12" offset-xl="3" offset-lg="2" xl="6" lg="8">
        <div v-for="issueData in statusData.issues"
             :key="issueData.id"
             class="mb-6"
             style="max-width:800px; margin-left:auto; margin-right:auto;"
        >
          <StatusIssueBlockCard
            :issue-data="issueData"
            :loading="isBusy"
          />
        </div>
        <div v-for="(item, i) in statusData.services"
             :key="i"
             class="mb-3"
             style="max-width:800px; margin-left:auto; margin-right:auto;"
        >
          <StatusServiceBlockCard
            :status-group-data="item"
            :loading="isBusy"
          />
        </div>

        <div
          style="max-width:800px; margin-left:auto; margin-right:auto;"
          v-if="!isBusy"
        >
          <StatusSubscriptionBlockCard :loading="isBusy"/>
        </div>

        <div style="max-width:800px; margin-left:auto; margin-right:auto;">
          <BasicCard :loading="true" v-if="isBusy">
            <p>Loading</p>
          </BasicCard>
        </div>
      </v-col>
  </v-row>
</template>

<script>

import apiMixin from '../../../mixins/apiMixin';
import StatusServiceBlockCard from "./StatusServiceBlockCard.vue";
import StatusIssueBlockCard from "./StatusIssueBlockCard.vue";
import BasicCard from "../templates/BasicCard.vue";
import StatusSubscriptionBlockCard from "./StatusSubscriptionBlockCard.vue";
export default {
    name: 'StatusMainDisplayCard',
    mixins: [apiMixin],
    data: () => ({
        busy:false,
        statusData: [],
    }),
    components: {
      StatusSubscriptionBlockCard,
      BasicCard,
      StatusServiceBlockCard,
      StatusIssueBlockCard
    },
    props: {
      updateCache: {
            type: Boolean,
            default: false,
        },
    },
    created(){
      this.update( );
    },
    computed:{
      isBusy: function () {
        return this.loading || this.busy;
      }
    },
    methods: {
        async update() {
            let params = {
              "update_cache":this.updateCache
            };
            let queryString = Object.keys(params).map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');

            this.busy = true;
            const response = await this.Api.send('get','/operational-status?'+queryString);
            this.busy = false;
            if(response.success){
              this.statusData = response.data;
            }else{
              this.statusData = [];
            }
        },
    },
};
</script>