<template>
    <v-form v-model="valid" ref="businessForm" v-on:submit.prevent="">
        <v-expand-transition>
            <v-row dense v-if="showSearch && !manualEntry">
                <v-col cols="12">
                    <ABRSearch
                        @selected="searchSelected"
                        @manual="manualEntry=true"
                        :show-manual-entry="!manualEntry && !showForm"
                    />
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-expand-transition>
            <v-row dense v-if="showSearch && manualEntry">
                <v-col cols="12">
                    <a @click="manualEntry=false">Click here</a> to search <span v-if="manualEntry">by ABN/ACN or business name</span><span v-else>again</span>
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-expand-transition>
            <v-row dense v-show="!!business.entity_type || !showSearch || showForm || manualEntry" class="pb-4">
                <v-col cols="12">
                    <p v-if="!manualEntry" class="pt-4">The below information has been pre-filled - please review and amend/add any details as required (such as "Trading name").</p>
                    <v-radio-group
                        class="mt-1"
                        v-model="business.entity_type"
                        label="Business entity type"
                        :rules="rules.required"
                        @change="changeEntityType"
                        row
                        hide-details
                    >
                        <v-radio label="Sole Trader / Partnership" :value="1" />
                        <v-radio label="Company / Association" :value="2" />
                        <v-radio label="Trust" :value="3" />
                    </v-radio-group>
                    <v-radio-group
                        v-if="business.entity_type===3"
                        v-model="business.trustee_type"
                        :rules="rules.required"
                        label="Trustee entity type"
                        row
                        hide-details
                    >
                        <v-radio label="Individual" :value="1" />
                        <v-radio label="Company" :value="2" />
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-expand-transition>
            <v-row dense v-if="business.entity_type===1 || (business.entity_type===3 && business.trustee_type===1)">
                <v-col cols="12" sm="2">
                    <v-combobox
                        v-model="business.title"
                        label="Title"
                        :items="data.titles"
                        :rules="rules.title"
                        hint="Optional"
                        outlined
                    />
                </v-col>
                <v-col cols="12" sm="5">
                    <v-text-field
                            v-model="business.firstname"
                            label="First Name *"
                            hint="Required"
                            outlined
                            :rules="rules.required"
                    />
                </v-col>
                <v-col cols="12" sm="5">
                    <v-text-field
                            v-model="business.lastname"
                            label="Last Name *"
                            hint="Required"
                            outlined
                            :rules="rules.required"
                    />
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-expand-transition>
            <v-row dense v-if="business.entity_type===2 || (business.entity_type===3 && business.trustee_type===2)">
                <v-col cols="12">
                    <v-text-field
                            v-model="business.entity_name"
                            :label="(business.entity_type===3 ? 'Trustee ' : '') + 'Entity Name *'"
                            outlined
                            :rules="rules.required"
                    />
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-expand-transition>
            <v-row dense v-if="business.entity_type===3">
                <v-col cols="12" sm="8">
                    <v-text-field
                        v-model="business.trust_name"
                        label="Trust Name *"
                        prefix="as trustee for"
                        :hint="manualEntry ? '' : 'Use the search box above to search for the trust entity by ABN or name'"
                        outlined
                        :rules="rules.required"
                    />
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field
                        v-model="business.trust_abn"
                        label="Trust ABN *"
                        outlined
                        :rules="rules.abn"
                    />
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-expand-transition>
            <v-row dense v-show="business.entity_type">
                <v-col cols="12" :sm="(business.entity_type===3 && business.trustee_type===1) ? 12 : 8">
                    <v-combobox
                        v-model="business.trading_name"
                        label="Trading name"
                        :items="business.business_names"
                        outlined
                    />
                </v-col>
                <v-col cols="12" sm="4" v-if="!(business.entity_type===3 && business.trustee_type===1)">
                    <v-text-field
                        v-model="business.abn_acn"
                        ref="businessNumber"
                        :label="(business.entity_type===3 ? 'Trustee ' : '') + 'ABN' + (business.entity_type!==1 ? ' or ACN' : '') + ' *'"
                        :rules="business.entity_type===1 ? rules.abn : rules.abn_acn"
                        outlined
                    />
                </v-col>
            </v-row>
        </v-expand-transition>
    </v-form>
</template>

<script>
import ABRSearch from './ABRSearch';
export default {
    name: 'BusinessDetailsForm',
    components: { ABRSearch },
    data: () => ({
        valid: false,
        manualEntry: false,
        showForm: false,
        street_number: '',
        business: {
            trustee_type: null,
            entity_name: '',
            entity_type: null,
            abn_acn: '',
            trading_name: '',
            trading_names: [],
            firstname: '',
            lastname: '',
            trust_name: '',
            trust_abn: ''
        },
        rules: {
            required: [
                v => !!v || 'Required',
            ],
            abn: [
                v => !!v || 'ABN is required',
                v => /^\d{11}$/.test(v) || 'ABN is not valid'
            ],
            abn_acn: [
                v => !!v || 'ABN/ACN is required',
                v => /^\d{9}(?:\d{2})?$/.test(v) || 'ABN/ACN is not valid'
            ],
            title: [
                v => !!v && v.length <= 5 || 'Title too long',
                v => !/[^A-Za-z]/.test(v) || 'Invalid title'
            ],
        },
        data: {
            titles: ['Dr', 'Miss', 'Mr', 'Mrs', 'Ms']
        },
    }),
    methods: {
        searchSelected(data) {
            this.showForm = true;
            if (data.entity_type===3) {
                this.business.trustee_type = this.business.entity_type;
            } else {
                this.business.trustee_type = data.entity_type;
            }
            this.business = {...this.business, ...data};
            this.$nextTick(() => {
                this.$refs.businessForm.validate();
            });
        },
        changeEntityType(data) {
            if (data===1 || data===2) this.business.trustee_type = data;
            this.$nextTick(() => {
                this.$refs.businessForm.validate();
            });

        },
        parseData() {
            if (!this.valid) return null;
            let parsed = {
                entity_type: this.business.entity_type,
                trading_name: this.business.trading_name,
            };
            switch (this.business.entity_type) {
                case 1:
                    parsed = {
                        ...parsed,
                        ...{
                            contact: {
                                title: this.business.title,
                                firstname: this.business.firstname,
                                lastname: this.business.lastname,
                            },
                            entity_name: this.business.firstname + ' ' + this.business.lastname,
                            abn_acn: this.business.abn_acn,
                        }
                    };
                    break;
                case 2:
                    parsed = {...parsed, ...{
                            entity_name: this.business.entity_name,
                            abn_acn: this.business.abn_acn
                        }
                    };
                    break;
                case 3:
                    parsed = {
                        ...parsed,
                        ...{
                            trustee_type: this.business.trustee_type,
                            trust_name: this.business.trust_name,
                            trust_abn: this.business.trust_abn,
                        }
                    };
                    if (this.business.trustee_type===1) {
                        parsed = {
                            ...parsed,
                            ...{
                                contact: {
                                    title: this.business.title,
                                    firstname: this.business.firstname,
                                    lastname: this.business.lastname,
                                },
                                entity_name: this.business.firstname + ' ' + this.business.lastname
                            }
                        };
                    } else if (this.business.trustee_type===2) {
                        parsed = {
                            ...parsed,
                            ...{
                                entity_name: this.business.entity_name,
                                abn_acn: this.business.abn_acn
                            }
                        };
                    }
                    break;
            };
            return parsed;
        }
    },
    watch: {
        business: {
            handler(data) {
                this.$emit('input', this.parseData());
            },
            deep: true
        },
        valid(value) {
            this.$emit('input', value ? this.parseData() : null);
        },
    },
    props: {
        showSearch: {
            type: Boolean,
            default: false
        },
        value: {
            default: null
        }
    }

};
</script>