<template>
    <v-tooltip left open-delay="200">
        <template v-slot:activator="{ on }">
            <v-fab-transition>
                <v-btn
                    v-on="on"
                    v-on:click="$emit('click')"
                    :color="color"
                    :large="$vuetify.breakpoint.mdAndUp || large"
                    :loading="loading"
                    v-show="visible"
                    fab
                    fixed
                    dark
                    bottom
                    right
                >
                    <v-icon>{{ icon }}</v-icon>
                </v-btn>
            </v-fab-transition>
        </template>
        {{ tooltip }}
    </v-tooltip>

</template>

<script>
export default {
    name: 'FloatingButton',
    data: () => ({
        visible: false,
        oldPosition: 0
    }),
    methods: {
        handleScroll(val) {
            let newPosition = window.pageYOffset;
            if (this.oldPosition < newPosition) {
                //scroll down - hide button
                if (this.visible && window.innerWidth<800) this.visible = false;
            } else if (this.oldPosition > newPosition) {
                //scoll up - show button
                if (!this.visible) this.visible = true;
            }
            this.oldPosition = newPosition;
        }
    },
    watch: {
        show: function (value) {
            this.visible = value;
        },
    },
    props: {
        icon: {
            type: String,
            default: 'mdi-plus',
        },
        color: {
            type: String,
            default: 'primary',
        },
        tooltip: {
            type: String,
            default: "What's this?",
        },
        show: {
            type: [Boolean, String],
            default: true,
        },
        large: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.visible = true;
        window.addEventListener('scroll', this.handleScroll, { passive: true });
    },

    beforeDestroy() {
        this.visible = false;
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>
