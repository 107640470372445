<template>
    <BasicCard :title="title" :loading="loading">
        <p class="title text--primary" v-if="data.user_id">
          <router-link :to="{name: 'AdminUserDetails', params: {userId: data.user_id}}">
            <span v-if="data.salutation">{{ data.salutation }} </span>
            <span v-if="data.firstname">{{ data.firstname }} </span>
            <span v-if="data.midinit">{{ data.midinit }} </span>
            <span v-if="data.lastname">{{ data.lastname }}</span>
          </router-link>
        </p>
        <p class="title text--primary" v-else>
            <span v-if="data.salutation">{{ data.salutation }} </span>
            <span v-if="data.firstname">{{ data.firstname }} </span>
            <span v-if="data.midinit">{{ data.midinit }} </span>
            <span v-if="data.lastname">{{ data.lastname }}</span>
        </p>
        <v-row dense v-if="data.phone1">
            <v-col cols="4" class="font-weight-bold"> Phone </v-col>
            <v-col cols="8">
                <PhoneNumber :value="data.phone1" />
            </v-col>
        </v-row>
        <v-row dense v-if="data.phone2">
            <v-col cols="4" class="font-weight-bold"> Mobile / SMS </v-col>
            <v-col cols="8">
                <PhoneNumber :value="data.phone2" />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="4" class="font-weight-bold"> Email </v-col>
            <v-col cols="8">
                <a :href="'mailto:' + data.email">{{ data.email }}</a>
                <CopyToClipboard :value="data.email" small />
            </v-col>
        </v-row>
      <v-row dense v-if="data.user_id">
        <v-col cols="4" class="font-weight-bold"> Username </v-col>
        <v-col cols="8">
          <span>{{ data.username }}</span>
          <CopyToClipboard :value="data.username" small />
        </v-col>
      </v-row>
        <template v-slot:actions >
            <ButtonWithTooltip
                :disabled="data == null || !allowEdit"
                text="Contact"
                icon="mdi-pencil"
                key="1"
                flat
                @click="$emit('edit',data)"
            />
          <ButtonWithTooltip
              text="User"
              icon="mdi-pencil"
              key="2"
              flat
              v-if="data.user_id != null"
              @click="showConvertToUser=true"
          /><ButtonWithTooltip
            v-if="data.user_id == null && createUser"
            text="Create User"
            icon="mdi-account-plus"
            key="3"
            flat
            @click="showConvertToUser=true"
        /><ButtonWithTooltip
            v-if="data.user_id != null && data.active_at != null"
            text="Disable Login"
            icon="mdi-account-minus"
            key="4"
            flat
            @click="disableLogin"
        /><ButtonWithTooltip
            v-if="data.user_id != null && data.active_at == null"
            text="Activate Login"
            icon="mdi-account-plus"
            key="5"
            flat
            @click="activateLogin"
        /></template>
        <template v-slot:feature>
            <v-chip v-if="data.user_id && data.active_at != null" color="info" label small>User</v-chip>
            <v-chip v-if="data.authorized==1" class="ml-1" color="success" label small>Authorized</v-chip>
        </template>
      <ConvertToUserDialog v-model="showConvertToUser" :contact="data" :partnerId="partnerId" :title="(data.user_id == null ? 'Create Portal Login' : 'Update Portal Login')"/>
    </BasicCard>
</template>

<script>
import BasicCard from './templates/BasicCard';
import PhoneNumber from '../pieces/PhoneNumber';
import CopyToClipboard from '../pieces/CopyToClipboard';
import ButtonWithTooltip from '../pieces/ButtonWithTooltip';
import ConvertToUserDialog from "../dialogs/ConvertToUserDialog";
import apiMixin from '../../mixins/apiMixin';
import dialogMixin from "../../mixins/dialogMixin";
export default {
    name: 'ContactCard',
    mixins: [apiMixin, dialogMixin],
    components: { ButtonWithTooltip, CopyToClipboard, PhoneNumber, BasicCard,ConvertToUserDialog },
    data: () => ({
      showConvertToUser: false,
    }),
    methods: {
      async disableLogin() {
        this.busy = true;
        let response = await this.Api.send('put', 'users/' + this.data.user_id+ '/disable-login',[]);
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.visible = false;
        }
        this.busy = false;
      },
      async activateLogin() {
        this.busy = true;
        let response = await this.Api.send('put', 'users/' + this.data.user_id+ '/activate-login',[]);
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.visible = false;
        }
        this.busy = false;
      }
    },
    props: {
        data: {
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        allowEdit: {
          type: Boolean,
          default: false,
        },
        title: {
            type: String,
            default: null,
        },
        createUser:{
            type: Boolean,
            default: false,
        },
        partnerId: {
          type: Number,
          default: null,
        },
    },
};
</script>

<style scoped></style>
