<template>
    <span>{{ value | shortState }}</span>
</template>

<script>
export default {
    name: 'AddressState',
    filters: {
        shortState: function (value) {
            switch (value) {
                case 'South Australia':
                    return 'SA';
                case 'Northern Territory':
                    return 'NT';
                case 'Western Australia':
                    return 'WA';
                case 'Queensland':
                    return 'QLD';
                case 'New South Wales':
                    return 'NSW';
                case 'Victoria':
                    return 'VIC';
                case 'Tasmania':
                    return 'TAS';
                case 'Australian Capital Territory':
                    return 'ACT';
                case '':
                    return '-';
                default:
                    return value;
            }
        },
    },
    props: {
        value: {
            type: String,
            default: null,
        },
    },
};
</script>
