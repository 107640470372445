<template>
    <span>
        <a :href="'tel:' + phone">{{ value }}</a>
        <!--v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" :href="'tel:'+phone" small icon text><v-icon small>mdi-phone</v-icon></v-btn>
            </template>
            <span>Call</span>
        </v-tooltip -->
        <!-- v-tooltip v-if="mobile" bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="$emit('sms')" small icon text><v-icon small>mdi-cellphone</v-icon></v-btn>
            </template>
            <span>Send SMS</span>
        </v-tooltip -->
        <CopyToClipboard v-if="phone" :value="phone" small />
    </span>
</template>

<script>
import CopyToClipboard from './CopyToClipboard';
export default {
    name: 'PhoneNumber',
    components: { CopyToClipboard },
    methods: {
        stripSpaces: function (value) {
            if (value) {
                return value.replace(/\s/g, '');
            } else {
                return '';
            }
        },
        isMobile: function (value) {
            let mobile = new RegExp(/(0|61)?4[0-9]{8}/g);
            return mobile.test(this.stripSpaces(value));
        },
    },
    data() {
        return {
            phone: this.stripSpaces(this.value),
        };
    },
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    computed: {
        mobile: function () {
            return this.isMobile(this.value);
        },
        icon: function () {
            return this.mobile ? 'mdi-cellphone' : 'mdi-phone';
        },
    },
    watch: {
        value: function (value) {
            this.phone = this.stripSpaces(value);
        },
    },
    created() {
        this.phone = this.stripSpaces(this.value);
    },
};
</script>
