import equalsMixin from './equalsMixin';
export default {
    data: () => ({
        filter: {
            limit: 10,
            page: 1,
        },
        filterDefaults: {},
    }),
    mixins: [equalsMixin],
    beforeRouteUpdate(to, from, next) {
        let filter = this.calculateFilterFromQuery(to.query);
        if (!this.equals(this.filter, filter)) {
            this.filter = filter;
        }
        next();
    },
    created() {
        this.filterDefaults = { ...this.filter };
        this.filter = this.calculateFilterFromQuery(this.$route.query);
    },
    methods: {
        updateFilter(data) {
            this.filter = { ...this.filter, ...data }
        },
        calculateFilterFromQuery(query) {
            let filter = { ...this.filterDefaults };
            for (var item in query) {
                switch(typeof this.filterDefaults[item]) {
                    case "number":
                        if (filter[item] !== Number(query[item]))
                            filter[item] = Number(query[item]);
                        break;
                    case "boolean":
                        if (filter[item] !== Boolean(query[item]))
                            filter[item] = Boolean(query[item]);
                        break;
                    case "object":
                        if (this.filterDefaults[item] === null) {
                            if (query[item] !== null && query[item] !== "")
                                filter[item] = query[item];
                            break;
                        }
                    default:
                        if (String(filter[item]) !== String(query[item]))
                            filter[item] = String(query[item]);
                        break;
                }
            };
            return filter;
        },
    },
    watch: {
        filter: {
            handler(val) {
                if (!this.equals(this.query, this.$route.query ? this.$route.query : {}))
                    this.$router.push({ query: this.query });
            },
            deep: true
        }
    },
    computed: {
        query: function() {
            let query = {};
            for (const item in this.filter) {
                if (this.filterDefaults[item] !== this.filter[item])
                    query[item] = String(this.filter[item]);
            };
            return query;
        }
    }
};