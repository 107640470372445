<template>
    <v-row dense>
        <v-col cols="12">
            <PartnerCommissionHistoryCard
                :filter="filter"
                @update="updateFilter"
            />
        </v-col>
    </v-row>
</template>

<script>
import PartnerCommissionHistoryCard from '../../components/cards/Partner/PartnerCommissionHistoryCard';
import filterMixin from '../../mixins/filterMixin';
import {mdiPhoneIncoming, mdiPhoneOutgoing} from "@mdi/js";
export default {
    name: 'PartnerCommissions',
    mixins: [filterMixin],
    components: {PartnerCommissionHistoryCard}
};
</script>

<style scoped></style>
