<template>
    <StatusMainDisplayCard />
</template>

<script>
import StatusMainDisplayCard from "../../components/cards/Status/StatusMainDisplayCard.vue";
export default {
    name: 'CustomerOperationalStatus',
    components: {
      StatusMainDisplayCard
    },
};
</script>
