<template>
    <ComplexDialog
        :title="dialogTitle"
        v-model="visible"
        :step="step"
        :loading="isBusy"
        :step-end="endStep"
        :step-next-disabled="nextStepDisabled"
        @step-next="next"
        @step-back="back"
        @step-end="finish"
        :step-end-text="'Close'"
        :step-next-text="nextStepText"
        :step-back-text="(step == 2 && (type == 'location-id' || searchAddresses.length == 1))  ? 'Close' : 'Back'"
        :max-width="onlyWireless ? 800 : 1300"
    >

      <v-window v-model="step" :touch="{left: null, right: null}">
        <v-window-item :value="1">
          <div v-if="this.searchAddresses != null && this.searchAddresses.length == 0">
            <v-alert type="warning" >No results could be found for this address.</v-alert>
            <p><strong>Address: </strong><AddressText :data="address"/></p>
          </div>
          <div v-else-if="this.searchAddresses != null && this.searchAddresses.length > 1">
            <p class="subtitle-1 text--primary">
              Available Address
            </p>
            <p>Multiple addresses have been found that match your query. Please select the correct address to continue.</p>
            <p>If you cannot find a suitable address then it may be that there is not connection available. Please call Breeze Connect for more information.</p>
            <v-radio-group v-if="!!this.searchAddresses" v-model="selectedAddress" class="mt-0 px-3">
              <v-radio
                  v-for="address in this.searchAddresses"
                  :key="address.id"
                  :value="address"
                  class="mb-3"
              >
                <template v-slot:label>
                  {{address.formattedAddress}}
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </v-window-item>
        <v-window-item :value="2">
          <div v-if="this.siteQualifyResponse != null && this.siteQualifyResponse.products.length == 0">
            <v-alert type="info" title="Qualification Failed"> This site does not qualify for an NBN connection. Please try a different address.</v-alert>
            <p v-if="type == 'address'"><strong>Address: </strong><AddressText  :data="address"/></p>
            <p v-else-if="selectedAddress != null"><strong>Address: </strong>{{selectedAddress.formattedAddress}}</p>
          </div>
          <div v-else-if="this.siteQualifyResponse!= null">
            <p v-if="type == 'address'"><strong>Address: </strong><AddressText  :data="address"/></p>
            <p v-else-if="selectedAddress != null"><strong>Address: </strong>{{selectedAddress.formattedAddress}}</p>
            <p>
              <span v-if="showSupportedSpeed">
                <strong>Max Supported Download Speed: </strong>{{siteQualifyResponse.download_max}} Mbps<br />
                <strong>Max Supported Upload Speed: </strong>{{siteQualifyResponse.upload_max}} Mbps<br />
              </span>
              <strong>Technology: </strong>{{siteQualifyResponse.technology_label}}
            </p>

            <p class="subtitle-1 text--primary">
              Available Products
            </p>
            <p>For details on NBN Enterprise Ethernet (EE) plans, please reach out to the Breeze Connect team (help@breezeconnect.com.au)</p>
            <p v-if="showCarrierReviewMessage">* Requires review from our carrier. Orders will be pending review.</p>
            <div class="d-md-none pa-3">
              <v-sheet
                  elevation="4"
                  :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center justify-center pa-6 mb-6'+($vuetify.theme.dark ? ' card-dark' : null)"
                  rounded
                  width="100%"
                  v-for="(item, i) in siteQualifyResponse.products"
                  :key="i"
              >
                <div>
                  <h2 class="text-h4 font-weight-black">{{item.title}}<span v-if="item.carrier_review"> *</span></h2>

                  <p class="text-body-2 mb-4">
                    <strong>People online at the same time on multiple devices: </strong>{{item.concurrent_devices}}<br />
                    <strong>Download/Upload speed: </strong>{{item.download_speed}}/{{item.upload_speed}} Mbps<span v-if="onlyWireless">*</span><br />
                    <strong>Business VoIP Phone: </strong><v-icon
                      v-if="item.voip_phone"
                      color="success"
                      class="mr-2"
                  >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                        class="mr-2"
                    >mdi-close</v-icon><br />
                    <strong>Emails and browsing: </strong><v-icon
                      v-if="item.email_browsing"
                      color="success"
                      class="mr-2"
                  >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                        class="mr-2"
                    >mdi-close</v-icon><br />
                    <strong>HD Video Streaming: </strong><v-icon
                      v-if="item.hd_streaming"
                      color="success"
                      class="mr-2"
                  >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                        class="mr-2"
                    >mdi-close</v-icon><br />
                    <strong>4K Video Streaming: </strong><v-icon
                      v-if="item.streaming_4k"
                      color="success"
                      class="mr-2"
                  >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                        class="mr-2"
                    >mdi-close</v-icon><br />
                    <strong>SLA Options: </strong><v-icon
                      v-if="item.sla_access"
                      color="success"
                      class="mr-2"
                  >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                        class="mr-2"
                    >mdi-close</v-icon>
                    <span v-if="showSupportedSpeed">
                      <br />
                      <strong>Site Speed Supported: </strong><v-icon
                        v-if="item.download_speed_supported"
                        color="success"
                    >mdi-check</v-icon>
                      <v-icon
                          v-else
                          color="error"
                      >mdi-close</v-icon>/<v-icon
                        v-if="item.upload_speed_supported"
                        color="success"
                    >mdi-check</v-icon>
                      <v-icon
                          v-else
                          color="error"
                      >mdi-close</v-icon>
                    </span>
                  </p>

                  <div class="text-h5 font-weight-medium mb-2"><Currency :value="item.price" hide-tooltip /></div>
                  <v-btn
                      v-if="customerData != null"
                      :color="((selectedPlan != null && selectedPlan.tag == item.tag) ? 'warning' : 'primary')"
                      @click="selectPlan(item)"
                  ><span v-if="selectedPlan != null && selectedPlan.tag == item.tag">Selected</span><span v-else>Select</span></v-btn>
                </div>
              </v-sheet>
              <div class="pt-10" style="font-size:12px" v-if="onlyWireless">* Our Fixed Wireless NBN plans have the potential to reach download speeds of up to 75Mbps, although approximately 50% of customers typically experience download speeds exceeding 25Mbps. It's important to note that wireless speeds can be influenced by factors such as obstacles obstructing the antenna's line of sight to the tower, weather conditions, as well as tower and network capacity. While we strive to provide the best possible service, these external variables may impact the actual speed experienced by individual users.</div>
            </div>
            <div class="d-none d-md-block">
            <v-simple-table v-if="siteQualifyResponse != null && siteQualifyResponse.products.length > 0">
              <thead>
              <tr>
                <th>NBN Plan</th>
                <th
                    v-for="(item, i) in siteQualifyResponse.products"
                    :key="i"
                    :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
                >{{item.title}}<span v-if="item.carrier_review"> *</span></th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td>People online at the same time on multiple devices</td>
                  <td
                      v-for="(item, i) in siteQualifyResponse.products"
                      :key="i"
                      :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
                  >{{item.concurrent_devices}}</td>
                </tr>
                <tr>
                  <td>Download/Upload speeds</td>
                  <td
                      v-for="(item, i) in siteQualifyResponse.products"
                      :key="i"
                      :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
                  >{{item.download_speed}}/{{item.upload_speed}} Mbps<span v-if="onlyWireless">*</span></td>
                </tr>
                <tr>
                  <td>Business VoIP Phone</td>
                  <td
                      v-for="(item, i) in siteQualifyResponse.products"
                      :key="i"
                      :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
                  ><v-icon
                      v-if="item.voip_phone"
                      color="success"
                      class="mr-2"
                  >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                        class="mr-2"
                    >mdi-close</v-icon></td>
                </tr>
                <tr>
                  <td>Emails and browsing</td>
                  <td
                      v-for="(item, i) in siteQualifyResponse.products"
                      :key="i"
                      :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
                  ><v-icon
                      v-if="item.email_browsing"
                      color="success"
                      class="mr-2"
                  >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                        class="mr-2"
                    >mdi-close</v-icon></td>
                </tr>
                <tr>
                  <td>HD Video Streaming</td>
                  <td
                      v-for="(item, i) in siteQualifyResponse.products"
                      :key="i"
                      :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
                  ><v-icon
                      v-if="item.hd_streaming"
                      color="success"
                      class="mr-2"
                  >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                        class="mr-2"
                    >mdi-close</v-icon></td>
                </tr>
                <tr>
                  <td>4K Video Streaming</td>
                  <td
                      v-for="(item, i) in siteQualifyResponse.products"
                      :key="i"
                      :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
                  ><v-icon
                      v-if="item.streaming_4k"
                      color="success"
                      class="mr-2"
                  >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                        class="mr-2"
                    >mdi-close</v-icon></td>
                </tr>
                <tr>
                  <td>SLA Options</td>
                  <td
                      v-for="(item, i) in siteQualifyResponse.products"
                      :key="i"
                      :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
                  ><v-icon
                      v-if="item.sla_access"
                      color="success"
                      class="mr-2"
                  >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                        class="mr-2"
                    >mdi-close</v-icon></td>
                </tr>
                <tr v-if="showSupportedSpeed">
                  <td>Site Speed Supported</td>
                  <td
                      v-for="(item, i) in siteQualifyResponse.products"
                      :key="i"
                      :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
                  ><v-icon
                        v-if="item.download_speed_supported"
                        color="success"
                    >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                    >mdi-close</v-icon>/<v-icon
                        v-if="item.upload_speed_supported"
                        color="success"
                    >mdi-check</v-icon>
                    <v-icon
                        v-else
                        color="error"
                    >mdi-close</v-icon></td>
                </tr>
                <tr>
                  <td>Total minimum cost</td>
                  <td
                      v-for="(item, i) in siteQualifyResponse.products"
                      :key="i"
                      :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
                  ><Currency :value="item.price" hide-tooltip />
                  </td>
                </tr>
              <tr v-if="customerData != null">
                <td></td>
                <td
                    v-for="(item, i) in siteQualifyResponse.products"
                    :key="i"
                    :class="((selectedPlan != null && selectedPlan.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
                >
                  <v-btn
                      :color="((selectedPlan != null && selectedPlan.tag == item.tag) ? 'warning' : 'primary')"
                      @click="selectPlan(item)"
                  ><span v-if="selectedPlan != null && selectedPlan.tag == item.tag">Selected</span><span v-else>Select</span></v-btn>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
              <div class="pt-10" style="font-size:12px" v-if="onlyWireless">* Our Fixed Wireless NBN plans have the potential to reach download speeds of up to 75Mbps, although approximately 50% of customers typically experience download speeds exceeding 25Mbps. It's important to note that wireless speeds can be influenced by factors such as obstacles obstructing the antenna's line of sight to the tower, weather conditions, as well as tower and network capacity. While we strive to provide the best possible service, these external variables may impact the actual speed experienced by individual users.</div>
              </div>
          </div>
        </v-window-item>
        <v-window-item :value="3">
          <p class="subtitle-1 text--primary">
            Available Support Packs
          </p>

          <div class="d-md-none pa-3">
            <v-row>
              <v-col cols="12" sm="6"
                     v-for="(item, i) in supportPackOptions"
                     :key="i"
              >
                <v-sheet
                    elevation="4"
                    :class="((selectedSupportPack != null && selectedSupportPack.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center justify-center pa-6 mb-6'+($vuetify.theme.dark ? ' card-dark' : null)"
                    rounded
                    width="100%"
                >
                  <div>
                    <h2 class="text-h5 font-weight-black">{{item.title}}</h2>

                    <p class="text-body-2 mb-4">
                      <strong>eSLA: </strong>{{item.esla}}<br />
                    </p>

                    <div class="text-h5 font-weight-medium mb-2"><Currency :value="item.price" hide-tooltip /></div>

                    <v-btn
                        v-if="selectedPlan && (selectedPlan.sla_access || item.tag == 'none')"
                        :color="((selectedSupportPack != null && selectedSupportPack.tag == item.tag) ? 'warning' : 'primary')"
                        @click="selectSupportPack(item)"
                    ><span v-if="selectedSupportPack != null && selectedSupportPack.tag == item.tag">Selected</span><span v-else>Select</span></v-btn>
                    <v-btn
                        v-else
                        disabled
                    >Select</v-btn>

                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </div>

          <v-simple-table class="d-none d-md-block">
            <thead>
            <tr>
              <th>Support Pack</th>
              <th
                  v-for="(item, i) in supportPackOptions"
                  :key="i"
                  :class="((selectedSupportPack != null && selectedSupportPack.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
              >{{item.title}}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>eSLA</td>
              <td
                  v-for="(item, i) in supportPackOptions"
                  :key="i"
                  :class="((selectedSupportPack != null && selectedSupportPack.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
              >{{item.esla}}</td>
            </tr>
            <tr>
              <td>Price</td>
              <td
                  v-for="(item, i) in supportPackOptions"
                  :key="i"
                  :class="((selectedSupportPack != null && selectedSupportPack.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
              ><Currency :value="item.price" hide-tooltip /></td>
            </tr>
            <tr v-if="customerData != null">
              <td></td>
              <td
                  v-for="(item, i) in supportPackOptions"
                  :key="i"
                  :class="((selectedSupportPack != null && selectedSupportPack.tag == item.tag) ? $vuetify.theme.dark ? 'selected-dark ' : 'selected ' : '')+'text-center'"
              >
                <v-btn
                    v-if="selectedPlan && (selectedPlan.sla_access || item.tag == 'none')"
                    :color="((selectedSupportPack != null && selectedSupportPack.tag == item.tag) ? 'warning' : 'primary')"
                    @click="selectSupportPack(item)"
                ><span v-if="selectedSupportPack != null && selectedSupportPack.tag == item.tag">Selected</span><span v-else>Select</span></v-btn>
                <v-btn
                    v-else
                    disabled
                >Select</v-btn>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-window-item>
        <v-window-item :value="4">
          <v-row>
            <v-col cols="0" md="2" lg="4">
            </v-col>
            <v-col cols="12" md="8" lg="4">
              <v-switch class="ma-0 pa-2" v-model="extraOptions.staticIp" hide-details :label="extraOptions.staticIp ? 'Static IP Required (+$4.95)' : 'Static IP Not Required'" />
              <v-radio-group
                v-model="extraOptions.transfer"
                row
              >
                <v-radio
                  label="New Connection"
                  value="new-connection"
                ></v-radio>
                <v-radio
                  label="Transfer"
                  value="transfer"
                ></v-radio>
              </v-radio-group>
              <p v-if="extraOptions.transfer == 'new-connection'">Note that some new connection installs may incur an install fee up to $300.</p>
              <v-checkbox
                v-if="siteQualifyResponse && siteQualifyResponse.technology_abbr && siteQualifyResponse.technology_abbr == 'Fibre'"
                v-model="extraOptions.forgoBatteryBackup"
                label="The End User has given informed consent to this order without a Battery Backup Service."
                style="margin-top:6px;"
                :error-messages="extraOptions.forgoBatteryBackup !== true ? ['Consent is mandatory.'] : []"
              />
              <v-text-field
                v-model="extraOptions.delayNote"
                label="Delay Note"
                hint="Is there a delay requirement for this connection."
                outlined
                maxlength="100"
              />
            </v-col>
            <v-col cols="0" md="2" lg="4">
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :value="5">
          <v-alert type="warning"
            v-if="showSupportedSpeed && selectedPlan && (selectedPlan.download_speed_supported != true || selectedPlan.upload_speed_supported != true)"
          >Note that the selected site/address does not support the selected plans maximum download and upload speeds.</v-alert>

          <p class="subtitle-1 text--primary">Please review the information below to ensure it is correct.</p>

          <v-sheet
              :color="'grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')"
              class="pa-4 mb-4"
              rounded
              v-if="selectedPlan != null"
          >
            <p class="text-h5">Customer</p>
            <v-row dense>
              <v-col cols="4" class="font-weight-bold">
                Name
              </v-col>
              <v-col cols="8">
                {{customerData.salutation}} {{customerData.firstname}} {{customerData.lastname}}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="font-weight-bold">
                Email
              </v-col>
              <v-col cols="8">
                {{customerData.email}}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="font-weight-bold">
                Address
              </v-col>
              <v-col cols="8">
                <AddressTextPortaData :porta-data="customerData"/>
              </v-col>
            </v-row>
            <v-row dense v-if="customerData.phone1 != ''">
              <v-col cols="4" class="font-weight-bold">
                Mobile
              </v-col>
              <v-col cols="8">
                {{customerData.phone1}}
              </v-col>
            </v-row>
            <v-row dense v-if="customerData.phone2 != ''">
              <v-col cols="4" class="font-weight-bold">
                Phone
              </v-col>
              <v-col cols="8">
                {{customerData.phone2}}
              </v-col>
            </v-row>
          </v-sheet>

          <v-sheet
              :color="'grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')"
              class="pa-4 mb-4"
              rounded
              v-if="selectedPlan != null"
          >
            <p class="text-h5">Site</p>
            <v-row dense>
              <v-col cols="4" class="font-weight-bold">
                Address
              </v-col>
              <v-col cols="8" v-if="type == 'address'">
                <AddressText :data="address"/>
              </v-col>
              <v-col cols="8" v-else>
                {{selectedAddress.formattedAddress}}
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="4" class="font-weight-bold">
                Max download speed
              </v-col>
              <v-col cols="8">
                {{siteQualifyResponse.download_max}} Mbps
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="4" class="font-weight-bold">
                Max upload speed
              </v-col>
              <v-col cols="8">
                {{siteQualifyResponse.upload_max}} Mbps
              </v-col>
            </v-row>

          </v-sheet>

          <v-sheet
              :color="'grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')"
              class="pa-4 mb-4"
              rounded
              v-if="selectedPlan != null"
          >
            <p class="text-h5">NBN Plan | {{selectedPlan.title}} - <Currency :value="selectedPlan.price" hide-tooltip /></p>

            <v-row dense>
              <v-col cols="6" class="font-weight-bold">
                People online at the same time on multiple devices:
              </v-col>
              <v-col cols="6">
                {{selectedPlan.concurrent_devices}}
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="6" class="font-weight-bold">
                Download/Upload speed:
              </v-col>
              <v-col cols="6">
                {{selectedPlan.download_speed}}/{{selectedPlan.upload_speed}} Mbps
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" class="font-weight-bold">
                Business VoIP Phone:
              </v-col>
              <v-col cols="6">
                <v-icon
                    v-if="selectedPlan.voip_phone"
                    color="success"
                    class="mr-2"
                >mdi-check</v-icon>
                <v-icon
                    v-else
                    color="error"
                    class="mr-2"
                >mdi-close</v-icon>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="6" class="font-weight-bold">
                Emails and browsing:
              </v-col>
              <v-col cols="6">
                <v-icon
                    v-if="selectedPlan.email_browsing"
                    color="success"
                    class="mr-2"
                >mdi-check</v-icon>
                <v-icon
                    v-else
                    color="error"
                    class="mr-2"
                >mdi-close</v-icon>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="6" class="font-weight-bold">
                HD Video Streaming:
              </v-col>
              <v-col cols="6">
                <v-icon
                    v-if="selectedPlan.hd_streaming"
                    color="success"
                    class="mr-2"
                >mdi-check</v-icon>
                <v-icon
                    v-else
                    color="error"
                    class="mr-2"
                >mdi-close</v-icon>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" class="font-weight-bold">
                4K Video Streaming:
              </v-col>
              <v-col cols="6">
                <v-icon
                    v-if="selectedPlan.streaming_4k"
                    color="success"
                    class="mr-2"
                >mdi-check</v-icon>
                <v-icon
                    v-else
                    color="error"
                    class="mr-2"
                >mdi-close</v-icon>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" class="font-weight-bold">
                SLA Options:
              </v-col>
              <v-col cols="6">
                <v-icon
                    v-if="selectedPlan.sla_access"
                    color="success"
                    class="mr-2"
                >mdi-check</v-icon>
                <v-icon
                    v-else
                    color="error"
                    class="mr-2"
                >mdi-close</v-icon>
              </v-col>
            </v-row>

            <v-row dense v-if="showSupportedSpeed">
              <v-col cols="6" class="font-weight-bold">
                Site Speed Supported:
              </v-col>
              <v-col cols="6">
                <v-icon
                    v-if="selectedPlan.upload_speed_supported"
                    color="success"
                >mdi-check</v-icon>
                <v-icon
                    v-else
                    color="error"
                >mdi-close</v-icon>/<v-icon
                  v-if="selectedPlan.download_speed_supported"
                  color="success"
              >mdi-check</v-icon>
                <v-icon
                    v-else
                    color="error"
                >mdi-close</v-icon>
              </v-col>
            </v-row>

          </v-sheet>

          <v-sheet
              :color="'grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')"
              class="pa-4 mb-4"
              rounded
              v-if="selectedSupportPack != null"
          >
            <p class="text-h5">Support Pack | {{selectedSupportPack.title}} - <Currency :value="selectedSupportPack.price" hide-tooltip /></p>
            <v-row dense>
              <v-col cols="3" class="font-weight-bold">
                eSLA
              </v-col>
              <v-col cols="9">
                {{selectedSupportPack.esla}}
              </v-col>
            </v-row>
          </v-sheet>

          <v-sheet
            :color="'grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')"
            class="pa-4 mb-4"
            rounded
          >
            <p class="text-h5">Extra Options</p>
            <v-row dense>
              <v-col cols="3" class="font-weight-bold">
                Static IP
              </v-col>
              <v-col cols="9">
                <span v-if="extraOptions.staticIp">Required (+$4.95)</span>
                <span v-else>No Required</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3" class="font-weight-bold">
                Connection Type
              </v-col>
              <v-col cols="9">
                <span v-if="extraOptions.transfer == 'transfer'">Transfer</span>
                <span v-else>New Connection</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3" class="font-weight-bold" v-if="extraOptions.delayNote != null && extraOptions.delayNote != ''">
                Delay Note
              </v-col>
              <v-col cols="9">
                <p>{{extraOptions.delayNote}}</p>
              </v-col>
            </v-row>
          </v-sheet>

          <v-sheet
              :color="'grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')"
              class="pa-4 mb-4 text-right"
              rounded
              v-if="selectedPlan != null && selectedSupportPack != null"
          >
            <h4 class="text-h4">Monthly Price: <Currency :value="selectedPlan.price + selectedSupportPack.price + (extraOptions.staticIp?4.95:0)" hide-tooltip /></h4>
          </v-sheet>
        </v-window-item>
        <v-window-item :value="6">
          <v-row no-gutters>
            <v-col cols="12" class="text-center py-4" v-if="submitStatus == true">
              <v-icon size="120" color="success">mdi-check-circle-outline</v-icon>
              <p class="text-h6">NBN order created successfully</p>
              <p>Thank you for your order. A staff member from Breeze Connect will be contacting you in the coming days.</p>
            </v-col>
            <v-col cols="12" class="text-center py-4"  v-if="submitStatus == false">
              <v-icon size="120" color="error">mdi-close-circle-outline</v-icon>
              <p class="text-h6">Failed to create NBN order</p>
              <p>If you are having trouble placing your NBN order please contact Breeze Connect directly.</p>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </ComplexDialog>
</template>

<script>
import apiMixin from '@/mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import ComplexDialog from "../templates/ComplexDialog";
import AddressText from "../../pieces/Address/AddressText";
import Currency from "../../pieces/Currency";
import AddressTextPortaData from "../../pieces/Address/AddressTextPortaData";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
  name: 'NBNProductOrderDialog',
  mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
  components: {
    AddressTextPortaData,
    Currency,
    AddressText,
    ComplexDialog,
  },
  data: () => ({
    step: 1,
    busy: false,
    searchAddresses:null,//list of address returned by the address search
    selectedAddress: null,
    selectedLocId:null, //the loc id from the kinetic address search. Not that multiple address can be returned and the user will need to make a selection.
    siteQualifyResponse: null,//
    selectedPlan:null,
    selectedSupportPack:'none',
    submitStatus:null,
    extraOptions:{
      staticIp:false,
      transfer:'new-connection',
      delayNote:null,
      forgoBatteryBackup:false,
    },
    showSupportedSpeed:true, //toggle is we show the supported speeds row. EG if only wireless is returned we do not show.
    onlyWireless:false, //toggle if the only plan show is wireless.
    showCarrierReviewMessage:false, //toggle if at least one product needs carrier review
    supportPackOptions:[
      {
        "title":"None",
        "tag":'none',
        "price":0,
        "esla":"no SLA",
      },
      {
        "title":"Assurance Basic",
        "tag":'assurance-basic',
        "price":33,
        "esla":"12hr eSLA"
      },
      {
        "title":"Assurance Plus",
        "tag":'assurance-plus',
        "price":45,
        "esla":"8hr eSLA"
      },
      {
        "title":"Assurance Advanced",
        "tag":'assurance-advanced',
        "price":65,
        "esla":"4hr eSLA"
      },
    ],
    rules: {
      description: [
        (v) =>
            !v || v.length <= 41 ||
            'Description must be 41 characters or less',
      ],
      cont1: [
        (v) =>
            !v || v.length <= 120 ||
            'Name / Billing Reference must be 120 characters or less',
      ],
      first_name: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1) || 'First name must contain at least 2 characters.'
      ],
      last_name: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1) || 'Last name must contain at least 2 characters.'
      ],
      email: [
        (v) => !!v || 'Required',
        (v) => /^.+@.+\..+$/.test(v) || 'Invalid email',
      ],
      phone1: [
        (v) => !!v || 'Required',
        (v) => (/^(?:0[2,3,5-9]\d{8})?$/.test(v) || /^(?:04\d{8})?$/.test(v)) || 'Invalid Phone/Mobile number.'
      ],
      required: [
        (v) => !!v || 'Required',
          ]
    }
  }),

  computed:{
    endStep( ){
      if(this.customerData != null){
        if(this.type != 'location-id' && (this.searchAddresses == null || this.searchAddresses.length==0)){
          return 1;
        }else if(this.siteQualifyResponse == null || this.siteQualifyResponse.products.length == 0){
          return 2;
        }else{
          return 6;
        }
      }else{
        if(this.type != 'location-id' && (this.searchAddresses == null || this.searchAddresses.length==0)){
          return 1;
        }else{
          return 2;
        }
      }
    },
    nextStepDisabled( ){
      if(this.isBusy){
        return true;
      }else if(this.step == 1){
        if(this.type == 'address'){
          if(this.selectedAddress == null){
            return true;
          }
        }else if(this.type == 'location-id'){
          if(this.locationID == null){
            return true;
          }
        }else if(this.type == 'advanced-search'){
          if(this.selectedAddress == null){
            return true;
          }
        }
      }else if(this.step == 2 && this.selectedPlan == null){
        return true;
      }else if(this.step == 3 && this.selectedSupportPack == null){
        return true;
      }else if(this.step == 4 && this.siteQualifyResponse.technology_abbr == 'Fibre' && this.extraOptions.forgoBatteryBackup !== true) {
        return true;
      }
      return false;
    },
    nextStepText( ){
      if(this.step == this.endStep){
        return 'Close';
      }else if(this.step == 5) {
        return 'Place Order';
      }else{
        return 'Next';
      }
    },
    isBusy( ){
      return this.loading || this.busy
    },
    dialogTitle( ){
      let title = '';
      if(this.partnerData != null){
        if(this.step == 1){
          title = 'Select Address - NBN Site Qualification';
        }else if(this.step == 2){
          title = 'Available Products - NBN Site Qualification';
        }else{
          title = 'NBN Site Qualification';
        }
      }else{
        if(this.step == 1){
          title = 'Select Address - Order NBN Connection';
        }else if(this.step == 2){
          title = 'Available Products - Order NBN Connection';
        }else if(this.step == 3){
          title = 'Available Support Packs - Order NBN Connection';
        }else if(this.step == 4){
          title = 'Extra Options';
        }else if(this.step == 5){
          title = 'Review - Order NBN Connection';
        }else if(this.step == 6){
          title = 'Completed - Order NBN Connection';
        }else{
          title = 'unknown';
        }
      }
      if(this.isBusy){
        title = 'Loading - '+title;
      }
      return title;
    }
  },
  watch: {
    visible(value) {
      if (value) {
        this.reset( );
        if(this.type == 'address'){
          this.getAddressSearch();
        }else if(this.type == 'advanced-search'){
          this.getAdvancedAddressSearch();
        }else if(this.type == 'location-id'){
          this.selectedLocId = this.locationID;
          this.next();
        }else{
          throw 'Unknown type ('+this.type+').';
        }
      }
    },
  },
  methods: {
    reset( ){
      this.step = 1;
      this.searchAddresses = null;
      this.selectedAddress=null;
      this.selectedLocId=null;
      this.siteQualifyResponse=null;
      this.selectedPlan=null;
      this.selectedSupportPack = null;
      this.submitStatus = null;
      this.showSupportedSpeed = true;
      this.onlyWireless = false;
      this.showCarrierReviewMessage = false;
      this.extraOptions = {
        staticIp:false,
        transfer:'new-connection',
        delayNote:null,
        forgoBatteryBackup:false,
      };
    },

    selectPlan(item){
      this.selectedPlan = item;
      this.selectedSupportPack = null;
    },
    selectSupportPack(item){
      this.selectedSupportPack = item;
    },

    async getAddressSearch( ){
      this.busy = true;
      let self = this;

      let params = this.address;

      let queryString = Object.keys(params).map((key) => {
        if(params[key] != null) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }
      }).join('&');

      let response = await this.Api.send('get', 'nbn/address-search?'+queryString);
      this.busy = false;
      if (response.success) {
        this.searchAddresses = response.data;
        if(this.searchAddresses.length == 1) {
          this.selectedAddress = this.searchAddresses[0];
          this.selectedLocId = this.searchAddresses[0].id;
          this.next();
        }
      }
    },

    async getAdvancedAddressSearch( ){
      this.busy = true;
      let self = this;

      let queryParams = [];

      for (let key in this.address) {
        if (this.address.hasOwnProperty(key) && this.address[key] !== null) {
          queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(this.address[key])}`);
        }
      }

      let response = await this.Api.send('get', 'nbn/advanced-address-search?'+queryParams.join('&'));
      this.busy = false;
      if (response.success) {
        this.searchAddresses = response.data;
        if(this.searchAddresses.length == 1) {
          this.selectedAddress = this.searchAddresses[0];
          this.selectedLocId = this.searchAddresses[0].id;
          this.next();
        }
      }
    },

    async getSiteQualification( ){
      this.busy = true;
      let params = {
        "addressLocId":this.selectedLocId,
      };
      if(this.type == 'location-id'){
        params.withAddress = true;
      }
      let queryString = Object.keys(params).map((key) => {
        if(params[key] != null) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }
      }).join('&');
      this.Api.setHttpObject({"timeout":60000});
      let response = await this.Api.send('get', 'nbn/site-qualification?'+queryString);
      this.busy = false;
      if (response.success) {
        this.siteQualifyResponse = response.data;
        if(this.type == 'location-id'){
          this.selectedAddress = response.data.addressDetails;
        }
        if(response.data.products.length == 1){
          if(response.data.products[0].tag == 'wireless'){
            this.showSupportedSpeed = false;
            this.onlyWireless = true;
          }
        }

        if(response.data.products.length > 0){
          //check to see if we need to show the carrier_review message
          for (let key in response.data.products) {
            if(response.data.products[key].carrier_review){
              this.showCarrierReviewMessage = true;
              break;
            }
          }
        }
      }else{
        let error = 'Failed to query NBN products.';
        if(response.error){
          error += ' '+response.error;
        }
        this.showGlobalErrorMessage(error);
      }
      this.Api.setHttpObject({"timeout":20000});
    },

    async submitOrder( ){
      this.busy = true;
      let params = {
        "kinetixAddress":this.selectedAddress,
        //"address":this.address,
        "selectedPlan":this.selectedPlan,
        "selectedSupportPack":this.selectedSupportPack,
        "extraOptions":this.extraOptions,
        "i_customer":this.customerData.i_customer,
      };
      if(this.type == 'address'){
        params.inputtedAddress = this.address
      }
      let response = await this.Api.send('post', 'nbn/place-order',params);
      this.busy = false;
      if (response.success) {
        this.submitStatus = true;
      }else{
        this.submitStatus = false;
      }
    },

    async next() {
      if(this.step == 1){
        if(this.type == 'address'){
          if(this.selectedAddress == null){
            //missing selected address
            this.showGlobalErrorMessage('Cannot continue. No address selected.');
          }
          this.selectedLocId = this.selectedAddress.id;
        }else if(this.type == 'location-id'){
          this.selectedLocId = this.locationID;
        }else if(this.type == 'advanced-search'){
          this.selectedLocId = this.selectedAddress.id;
        }else{
          throw 'Unknown type ('+this.type+').';
        }
        this.getSiteQualification( );
        this.step = 2;
      }else if(this.step == 2){
        this.step = 3;
      }else if(this.step == 3){
        this.step = 4;
      }else if(this.step == 4){
        if(this.siteQualifyResponse && this.siteQualifyResponse.technology_abbr && this.siteQualifyResponse.technology_abbr == 'Fibre' && this.extraOptions.forgoBatteryBackup !== true){
          //consent is missing
        }else {
          this.step = 5;
        }
      }else if(this.step == 5){
        this.submitOrder( );
        this.step = 6;
      }
    },
    back() {
      if(this.step == 2) {
        if ((this.locationID == null || this.locationID == '') && this.searchAddresses.length != 1) {
          this.step = 1;
        } else {
          //no point in going back to step 1. If there is only 1 address it will move to step 2. We close instead
          //or we passed in a locationId
          this.visible = false;
        }
      }else {
        this.step--;
      }
    },
    finish() {
      this.visible = false;
      this.$emit('finished');
    },
  },
  props: {
    /**
     * If viewing as a wholesale user  we pass the reseller details here.
     * Not that this UI can be used without a customer by a partner.
     */
    resellerData: {
      type: Object,
      default: null,
    },
    /**
     * If viewing as a partner we pass the partner details here.
     * Not that this UI can be used without a customer by a partner.
     */
    partnerData: {
      type: Object,
      default: null,
    },

    /**
     * IF viewed by a customer we pass in the detail here.
     * Meaning here are running the process for a customer.
     */
    customerData: {
      type: Object,
      default: null,
    },
    address:{
      type: Object,
      default: null
    },
    locationID:{
      type:String,
      default:null,
    },
    type:{
      type:String,
      default:'address',
    },
    loading:{
      type: Boolean,
      default: false
    }
  },

};
</script>
<style scoped>
.selected{
  background-color: #fdf0df;
}
.selected-dark{
  background-color: #797167;

}
.card-dark{
  box-shadow: 0px 2px 4px -1px rgb(255 255 255 / 20%), 0px 4px 5px 0px rgb(255 255 255 / 14%), 0px 1px 10px 0px rgb(255 255 255 / 12%) !important;
}
</style>