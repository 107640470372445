<template>
    <v-tooltip bottom :disabled="hideTooltip" open-delay="200">
        <template v-slot:activator="{ on }">
            <span v-on="on">
                <span v-if="!relative">
                    {{ value | luxon('dd/MM/yyyy') }}
                    <span v-if="showTime">
                        {{ value | luxon('h:mm' + (showSeconds ? ':ss' : '') + ' a') }}
                    </span>
                </span>
                <span v-if="relative">{{ value | luxon('relative') }}</span>
            </span>
        </template>
        {{ value | luxon('cccc, d MMMM yyyy @ h:mm:ss a') }}
    </v-tooltip>
</template>

<script>
export default {
    name: 'LocalDateTime',
    props: {
        value: {
            type: String,
            default: null,
        },
        relative: {
            type: Boolean,
            default: false,
        },
        showSeconds: {
            type: Boolean,
            default: false,
        },
        showTime: {
            type: Boolean,
            default: false,
        },
        hideTooltip: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
