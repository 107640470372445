<template>
    <div>
        <PageAlert
            v-if="filter.session_id"
            type="info"
            button
            button-text="Show All"
            @click="$emit('update', {session_id: null})"
            class="mb-2"
        >
            Only showing history for session ID {{filter.session_id}}.
        </PageAlert>
        <PaginationCard
                :title="title"
                :page="filter.page"
                :data="data"
                :limit="filter.limit"
                :loading="loading || isLoading"
                :no-skeleton="!!data"
                v-on:updateLimit="updateLimit"
                v-on:updatePage="updatePage"
        >
            <v-simple-table>
                <thead>
                <tr>
                    <th
                            v-if="$vuetify.breakpoint.smAndUp && showId"
                            class="text-left"
                    >
                        ID
                    </th>
                    <th class="text-left" v-if="!hideType">Type</th>
                    <th class="text-left">Detail</th>
                    <th class="text-left">User</th>
                    <th class="text-left">Date/Time</th>
                    <th
                            v-if="$vuetify.breakpoint.smAndUp"
                            class="text-center"
                    >
                        Linked Records
                    </th>
                  <th v-if="$store.state.user.isAdmin">Data</th>
                </tr>
                </thead>
                <!-- tbody -->
                <v-slide-x-transition tag="tbody" hide-on-leave group>
                    <tr v-if="data.data && data.data.length === 0" key="nodata">
                        <td colspan="100%" class="text-center">
                            No history found
                        </td>
                    </tr>
                    <tr v-for="item in data.data" :key="item.audit_log_id">
                        <td v-if="$vuetify.breakpoint.smAndUp && showId">
                            {{ item.audit_log_id }}
                        </td>
                        <td v-if="!hideType">
                            {{ item.audit_log_type }}
                        </td>
                        <td>{{ item.detail }}</td>
                        <td>
                            <router-link
                                v-if="item.user_id && $store.state.user.isAdmin"
                                link
                                :to="{ name: 'AdminUserDetails', params: { userId: item.user_id }}"
                            >
                                {{ item.firstname }} {{ item.lastname }}
                            </router-link>
                            <span v-if="item.user_id && !$store.state.user.isAdmin">{{ item.firstname }} {{ item.lastname }}</span>
                            <span v-if="!item.user_id">n/a</span>
                        </td>
                        <td>
                            <LocalDateTime
                                :value="item.datetime"
                                :relative="relativeDate"
                            />
                        </td>
                        <td v-if="$vuetify.breakpoint.smAndUp" class="caption text-center">
                            <v-chip
                                v-if="!filter.session_id && item.session_id"
                                :to="{ name: 'AdminUserHistory', params: { userId: item.user_id }, query: { session_id: item.session_id }}"
                                v-html="'Session ' + item.session_id"
                                small
                                label
                                class="px-2 ma-1"
                            />
                            <v-chip
                                v-if="!signupId && item.signup_id"
                                :to="{ name: 'SignupDetail', params: { id: item.signup_id }}"
                                v-html="'Signup ' + item.signup_id"
                                small
                                label
                                color="blue"
                                class="px-2 ma-1"
                            />
                            <v-chip
                                v-if="!accountId && item.i_account"
                                v-html="'Trunk ' + item.i_account"
                                small
                                label
                                color="secondary"
                                class="px-2 ma-1"
                            />
                            <v-chip
                                v-if="!custId && item.i_customer"
                                :to="{ name: 'CustomerSummary', params: { customerId: item.i_customer }}"
                                v-html="'Customer ' + item.i_customer"
                                small
                                label
                                color="primary"
                                class="px-2 ma-1"
                            />
                            <v-chip
                                v-if="item.did"
                                v-html="'DID ' + item.did"
                                small
                                label
                                color="secondary"
                                class="px-2 ma-1"
                            />
                            <span v-if="item.sms_id">
                                sms_id:{{ item.sms_id }}<br />
                            </span>
                            <span v-if="item.i_rep">
                                i_rep:{{ item.i_rep }}<br />
                            </span>
                        </td>
                      <td v-if="$store.state.user.isAdmin">
                        <span v-if="item.data != null">

                          <v-btn
                              outlined
                              @click="genShowData(item)"
                          >Show</v-btn>

                        </span>
                      </td>
                    </tr>
                </v-slide-x-transition>

            </v-simple-table>
        </PaginationCard>

      <SimpleDialog
          v-model="showDataVisible"
          title="View Data"
          :loading="isLoading"
          :okOnly="true"
      >
        <pre>{{JSON.stringify(JSON.parse(showData), null, 2)}}</pre>
      </SimpleDialog>

    </div>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import LocalDateTime from '../../pieces/LocalDateTime';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import PageAlert from '../../core/PageAlert';
import SimpleDialog from '../../dialogs/templates/SimpleDialog';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'HistoryCard',
    mixins: [apiMixin, paginationComponentMixin,dialogMixin],
    data: () => ({
        isLoading: false,
        data: [],
        showData: null,
        showDataVisible: false,
    }),
    components: {
        PageAlert,
        LocalDateTime,
        PaginationCard,
        SimpleDialog,
    },
    props: {
        hidePagination: {
            type: Boolean,
            default: false,
        },
        signupId: {
            type: Number,
            default: null,
        },
        userId: {
            type: Number,
            default: null,
        },
        repId: {
          type: Number,
          default: null,
        },
        custId: {
            type: Number,
            default: null,
        },
        accountId: {
            type: Number,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        relativeDate: {
            type: Boolean,
            default: false,
        },
        showId: {
            type: Boolean,
            default: false,
        },
        hideType: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async update() {
            this.isLoading = true;
            let params = { limit: this.filter.limit, page: this.filter.page };
            if (this.signupId) params.signup_id = this.signupId;
            if (this.userId) params.user_id = this.userId;
            if (this.custId) params.i_customer = this.custId;
            if (this.repId) params.i_rep = this.repId;
            if (this.filter.session_id) params.session_id = this.filter.session_id;
            const response = await this.Api.send('post','history', params);
            this.data = response.success ? response.data : [];
            this.isLoading = false;
        },
      genShowData(item){
        this.showData = item.data;
        this.showDataVisible = true;
      }
    },
};
</script>
