<template>
  <v-switch
      :label="label"
      :dense="dense"
      :hide-details="hideDetails"
      :disabled="disabled"
      v-model="input"
  />
</template>

<script>
export default {
  name: 'SwitchYesNoInput',
  data: () => ({
    input: false
  }),
  watch: {
    value(val) {
      let bool = val === 'Y';
      if (this.input !== bool) this.input = bool;
    },
    input(bool) {
      let val = bool ? 'Y' : 'N';
      if (this.value !== val) this.$emit('input', val);
    },
  },
  props: {
    label: {
      type: String,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: 'N'
    },
  },
  created() {
    this.input = this.value==='Y';
  }

};
</script>