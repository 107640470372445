export default {
    computed: {
        customerLoading() {
            return this.$store.state.loading.customer;
        },
        customerData() {
            if (
                this.$store.state.data.customer &&
                this.$store.state.data.customer.i_customer ===
                parseInt(this.$route.params.customerId)
            ) {
                return this.$store.state.data.customer;
            }
            return {};
        },
        customerId() {
            return parseInt(this.$route.params.customerId);
        },
        devicesPendingConfig( ){
            return this.customerData.devicesPendingConfig;
        }
    },
};