var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"password-input",attrs:{"dense":_vm.dense,"outlined":_vm.outlined,"hide-details":_vm.hideDetails,"type":_vm.show ? 'text' : 'password',"label":_vm.label,"hint":_vm.hint,"counter":32,"max-length":"32","error-messages":_vm.errorMessages,"rules":[
          function (v) { return !!v || 'Password is required'; },
          function (v) { return /[A-Z]/.test(v) ||
              'Password must contain at least 1 uppercase letter'; },
          function (v) { return /[a-z]/.test(v) ||
              'Password must contain at least 1 lowercase letter'; },
          function (v) { return /\d/.test(v) ||
              'Password must contain at least 1 number'; },
          function (v) { return /.{8}/.test(v) ||
              'Password must be at least 8 characters long'; },
          function (v) { return !!v && v.length <= 32 ||
              'Password must be 32 characters or less'; } ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(_vm.autoGenerate)?_c('v-btn',_vm._g(_vm._b({staticClass:"action-button",attrs:{"icon":""},on:{"click":_vm.generatePassword}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"innerHTML":_vm._s('mdi-refresh')}})],1):_vm._e()]}}])},[_c('span',[_vm._v("Generate new password")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-button",attrs:{"icon":""},on:{"click":function($event){_vm.show=!_vm.show}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"innerHTML":_vm._s(_vm.show ? 'mdi-eye-off' : 'mdi-eye')}})],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s((_vm.show ? 'Hide' : 'Show') + ' password')}})])]},proxy:true}]),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }