<template>
    <v-expand-transition>
        <v-row v-if="visible" no-gutters>
            <v-col cols="12">
                <v-card
                    :color="type"
                    elevation="4"
                    class="text--primary"
                >
                    <v-row align="center" no-gutters>
                        <v-col class="flex-grow-0 flex-shrink-1 my-4 ml-4">
                            <v-icon v-html="icon" class="text--primary" />
                        </v-col>
                        <v-col class="flex-grow-1 flex-shrink-0 ma-4 text--primary" >
                            <slot />
                            <v-btn v-if="button && $vuetify.breakpoint.xsOnly" :disabled="loading" outlined @click="$emit('click')" class="float-right mt-2 ml-2">{{buttonText}}</v-btn>
                        </v-col>
                        <v-col v-if="button && $vuetify.breakpoint.smAndUp" class="flex-grow-0 flex-shrink-1 my-4 mr-4">
                            <v-fade-transition>
                                <v-btn outlined :to="buttonTo" :href="buttonHref" :target="!!buttonHref ? '_blank' : null" :disabled="loading" @click="$emit('click')">{{buttonText}}</v-btn>
                            </v-fade-transition>
                        </v-col>
                        <v-col v-if="dismissable" class="flex-grow-0 flex-shrink-1 my-4 mr-4">
                            <v-btn large :disabled="loading" @click="visible=false" icon><v-icon v-html="'mdi-close-circle'" /></v-btn>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>
    </v-expand-transition>
</template>

<script>
    export default {
        name: 'PageAlert',
        data: () => ({
            visible: false,
        }),
        props: {
            type: {
                type: String,
                default: 'info'
            },
            icon: {
                type: String,
                default: 'mdi-alert'
            },
            dismissable: {
                type: Boolean,
                default: false
            },
            buttonText: {
                type: String,
                default: 'Details'
            },
            button: {
                type: Boolean,
                default: false
            },
            value: {
                type: Boolean,
                default: true
            },
            buttonTo: {
                default: null
            },
            buttonHref: {
                type: String,
                default: null
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            value(val) {
                if (val!==this.visible) this.visible = val;
            },
            visible(val) {
                if (this.visible!==val) this.$emit('input', val);
            }
        },
        mounted() {
            this.visible = this.value;
        },
        created() {

        }
    };
</script>

<style scoped>

</style>