<template>
    <v-form v-model="valid" v-on:submit.prevent="">
        <v-row dense>
            <v-col cols="12" sm="4">
                <v-text-field
                        v-model="user.username"
                        :label="'Username *'"
                        outlined
                        :rules="rules.required"
                        hint="Required. Must be unique."
                />
            </v-col>
            <v-col cols="12" sm="4" v-if="user.id == null">
              <PasswordField
                  v-model="user.password"
                  autoGenerate
                  outlined
                  :rules="rules.required && rules.password"
                  :label="'Password *'"
                  @passwordGenerated="passwordGenerated( )"
              />
            </v-col>
          <v-col cols="12" sm="4" v-if="user.id == null">
            <v-text-field
                v-model="user.passwordCheck"
                :label="'Re-type Password *'"
                outlined
                :rules="[
                            (v) =>
                                v === this.user.password ||
                                'Passwords do not match',
                        ]"
                type="password"
                hint="Re-type to match password."
            />
          </v-col>
        </v-row>
      <v-row dense v-if="user.id == null">
        <v-col cols="12">
          <div class="px-4 pb-4">
          <SwitchYesNoInput v-model="user.sendPasswordEmail" label="Send new user password reset email" dense hide-details />
          </div>
        </v-col>
      </v-row>
    </v-form>
</template>

<script>
import PasswordField from "../PasswordField";
import SwitchYesNoInput from "../SwitchYesNoInput";
export default {
    name: 'ConvertToUserDetailsForm',
  components: {SwitchYesNoInput, PasswordField},
  data: () => ({
        valid: false,
        user: {
            username: '',
            password: '',
            passwordCheck:'',
            sendPasswordEmail:false,
        },
        rules: {
            required: [
                v => !!v || 'Required',
            ],
            password: [
              v => !!v || 'Password is required',
              v => /[A-Z]/.test(v) || 'Password must contain at least 1 uppercase letter',
              v => /[a-z]/.test(v) || 'Password must contain at least 1 lowercase letter',
              v => /\d/.test(v) || 'Password must contain at least 1 number',
              v => /.{8}/.test(v) || 'Password must be at least 8 characters long',
            ],
            title: [
                v => v.length <= 5 || 'Title too long',
                v => !/[^A-Za-z]/.test(v) || 'Invalid title'
            ],
            phone: [
                v => !!v || 'At least one phone number is required',
                v => !/^04.*$/.test(v) || 'Mobile number must go in "Mobile Phone" field',
                v => /^(?:(?:0[2-47-8]\d{2}|(?:1300|1800))\d{6}|13\d{4})$/.test(v) || 'Must be a valid phone number'
            ],
            mobile: [
                v => !!v || 'At least one phone number is required',
                v => /^04\d{8}$/.test(v) || 'Must be a valid mobile number'
            ],
            email: [
                v => !!v || 'E-mail is required',
                v => /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || 'Must be a valid email address'
            ],
            bcc: [
                v => !v || /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || 'Not a valid email address'
            ],
        },
    }),
    methods: {
        updateData(data) {
            /*if (data===this.user) return;
            if (data) this.user = { ...this.user, ...data };*/
          //this.user = data;
        },
        passwordGenerated( ){
          this.user.passwordCheck = this.user.password;
        }
    },
    watch: {
        value(data) {
            this.updateData(data);
        },
        valid() {
            this.$emit('input', this.valid ? this.user : null)
        }
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        showBcc: {
            type: Boolean,
            default: false
        },
        prefill: {
          type: Object,
          default: null
        },
    },
    created() {
      this.user = this.prefill;
    }
};
</script>