<template>
  <ComplexDialog
      title="Select Customer Account Type"
      v-model="visible"

  >
    <v-container>
      <v-row class="pb-4">
        <v-col cols="12" sm="6">
          <div class="type-select-div">
            <h2>SIP Trunk</h2>
            <p><a @click="openLink('https://breezeconnect.com.au/plans/');">+ information</a></p>
            <v-btn
                elevation="2"
                x-large
                color="primary"
            @click="submit('sip-trunk')">Select</v-btn>
          </div>
        </v-col>
        <v-divider class="d-none d-sm-block" vertical></v-divider>
        <v-col cols="12" sm="6">
          <v-divider class="d-sm-none mb-4"></v-divider>
          <div class="type-select-div">
            <h2>Hosted Extension</h2>
            <p><a @click="openLink('https://breezeconnect.com.au/hpbx-calculator/');">+ information</a></p>
            <v-btn
                elevation="2"
                x-large
                color="primary"
                @click="submit('hosted-extension')">Select</v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div style="text-align:center;">
            <p style="color:#000000;">* <strong>NBN</strong>: Both customer types support NBN. If you are unsure select SIP Trunk (default).</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </ComplexDialog>
</template>

<script>

import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from "../../../mixins/dialogMixin";
import ComplexDialog from "../templates/ComplexDialog";
export default {
    name: 'NewCustomerAccountTypeDialog',
    mixins: [apiMixin,dialogMixin],
    components: {ComplexDialog},
    data() {
        return {
            busy: false,
        };
    },
    methods: {
      submit: function (type) {
        this.visible = false;
        this.$emit('type-selected',type);
      },
      openLink(href){
        window.open(href,'_blank');
      }
    }
};
</script>

<style scoped>
.type-select-div{
  text-align:center;
}
.type-select-div h2{
  margin-bottom:1rem;
}
</style>
