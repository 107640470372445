<template>
  <v-row dense>
    <v-col cols="12">
      <HistoryCard
          :rep-id="parseInt(this.$route.params.partnerId)"
          :loading="partnerLoading"
          @update="updateFilter"
          :filter="filter"
          show-filters
      />
    </v-col>
  </v-row>
</template>

<script>
import filterMixin from '../../mixins/filterMixin';
import partnerDataMixin from '../../mixins/partnerDataMixin';
import HistoryCard from '../../components/cards/shared/HistoryCard';
export default {
    name: 'PartnerHistory',
    mixins: [filterMixin, partnerDataMixin],
    components: {
        HistoryCard
    },
    data: () => ({
        filter: {
            limit: 10,
            page: 1,
        },
    })
};
</script>
