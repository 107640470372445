<template>
    <v-radio-group v-model="data" @change="update" class="px-3">
        <v-radio
            v-for="item in items"
            :key="item[itemValue]"
            :value="item[itemValue]"
            :disabled="!!item.disabled"
        >
            <template v-slot:label>
                <v-row no-gutters class="py-1 pl-2">
                    <v-col
                        cols="12"
                        :class="!!item.disabled ? '' : 'text--primary'"
                        >{{ item[itemLabel] }}</v-col
                    >
                    <v-col v-if="item[itemDescription]" cols="12" class="pt-1">{{
                        item[itemDescription]
                    }}</v-col>
                </v-row>
            </template>
        </v-radio>
    </v-radio-group>
</template>

<script>
export default {
    name: 'RadioSelect',
    data: () => ({
        data: '',
    }),
    methods: {
        update: function () {
            this.$emit('input', this.data);
        },
    },
    props: {
        value: {
            default: null,
            required: true,
        },
        items: {
            type: Array,
            default: null,
            required: true,
        },
        itemLabel: {
            type: String,
            default: 'label'
        },
        itemDescription: {
            type: String,
            default: 'description'
        },
        itemValue: {
            type: String,
            default: 'value'
        }
    },
    watch: {
        value: function (value) {
            this.data = value;
        },
    },
    created() {
        this.data = this.value;
    },
};
</script>