<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" v-if="partnerData && partnerData.hidden === 'Y'">
                <v-alert class="elevation-2 mb-1" type="error"
                    >This partner account is not active. This partner will have
                    no access until their account is active.</v-alert
                >
            </v-col>
        </v-row>
        <router-view />
    </v-container>
</template>

<script>
import partnerDataMixin from '../../mixins/partnerDataMixin';
import apiMixin from '../../mixins/apiMixin';
import refreshListenerMixin from '../../mixins/refreshListenerMixin';
export default {
    name: 'PartnerParent',
    mixins: [apiMixin, partnerDataMixin, refreshListenerMixin],
  /*
    If we are moving between partner pages and the id has been updated this will force and update.
   */
    beforeRouteUpdate(to, from, next) {
        if (to.params.partnerId && to.params.partnerId!==from.params.partnerId) this.get(to.params.partnerId);
        next();
    },

  /**
   * We run the create get call here instead of the partnerDataMixing.
   * Here we make sure the call only happens the once (rather then every instance of partnerDataMixing)
   */
  created() {
        this.get(this.$route.params.partnerId);
    },
};
</script>
