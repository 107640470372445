<template>
    <v-row dense>
        <v-col cols="12" lg="6">
          <SiteQualificationCard :loading="customerLoading" :customer-data="customerData" />
        </v-col>
    </v-row>
</template>

<script>
import SiteQualificationCard from "../../components/cards/NBN/SiteQualificationCard";
import customerDataMixin from "../../mixins/customerDataMixin";
export default {
    name: 'CustomerNBNSearch',
    mixins: [customerDataMixin],
    components: {
      SiteQualificationCard,
    },
};
</script>
