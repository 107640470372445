<template>
    <ComplexDialog
        v-model="visible"
        title="Change Commission Plan"
        persistent
        :loading="busy"
    >
            <v-alert
                type="info"
                text
                dense
            >Commission plan changes take effect immediately. This will not alter any commissions already calculated.</v-alert>
        <p>Please choose the new commission plan to apply to this partner.</p>
            <RadioSelect
                :items="commissionPlanData"
                item-description="name"
                item-value="i_commission_plan"
                v-model="i_commission_plan"
            />

        <template v-slot:actions>
            <v-spacer />
            <v-btn
                text
                v-on:click="save"
                color="primary"
                :disabled="!data || data.i_commission_plan==i_commission_plan"
                :loading="busy"
            >
                Apply
            </v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import commissionPlanDataMixin from '../../../mixins/commissionPlanDataMixin';
import RadioSelect from '../../pieces/Forms/RadioSelect';
export default {
    name: 'EditPartnerCommsPlan',
    components: { RadioSelect, ComplexDialog },
    mixins: [apiMixin, commissionPlanDataMixin, dialogMixin],
    data() {
        return {
            busy: false,
            display: true,
            i_cp_base: null,
            i_commission_plan: null,
        };
    },
    watch: {
        visible(value) {
            if (value) this.i_commission_plan = !!this.data ? this.data.i_commission_plan : null;
        }
    },
    methods: {
        async save(event) {
            event.preventDefault();
            this.busy = true;
            const response = await this.Api.send('post',
                'partners/' + this.data.i_rep + '/update',
                {
                    i_commission_plan: this.i_commission_plan,
                }
            );
            if (response.success) {
                this.$emit('close', true);
            }
            this.busy = false;
        },
    },
    props: {
        data: {
            type: Object,
            default: null
        }
    }
};
</script>
