<template>
    <div class="float-right">
        <v-tooltip bottom open-delay="200">
            <template v-slot:activator="{ on }">
                <v-btn
                    key="helpButton"
                    v-on="on"
                    v-on:click="$emit('click')"
                    icon
                >
                    <v-icon :color="color">{{ icon }}</v-icon>
                </v-btn>
            </template>
            <span>{{ tooltip }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    name: 'HelpButton',
    data: () => ({
        visible: false,
    }),
    watch: {
        show: function (value) {
            this.visible = value;
        },
    },
    props: {
        icon: {
            type: String,
            default: 'mdi-help',
        },
        color: {
            type: String,
            default: 'info',
        },
        tooltip: {
            type: String,
            default: "What's this?",
        },
        show: {
            type: [Boolean, String],
            default: true,
        },
    },
    created() {
        if (this.show) {
            this.visible = true;
        }
    },
};
</script>
