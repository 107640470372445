export default {
    data: () => ({
        addressData: {
            building_types: [
                { value: null, text: 'N/A' },
                { value: 'APT', text: 'Apartment' },
                { value: 'CTGE', text: 'Cottage' },
                { value: 'DUP', text: 'Duplex' },
                { value: 'FY', text: 'Factory' },
                { value: 'F', text: 'Flat' },
                { value: 'HSE', text: 'House' },
                { value: 'KSK', text: 'Kiosk' },
                { value: 'MSNT', text: 'Maisonette' },
                { value: 'MB', text: 'Marine Berth' },
                { value: 'OFF', text: 'Office' },
                { value: 'PTHS', text: 'Penthouse' },
                { value: 'RM', text: 'Room' },
                { value: 'SHED', text: 'Shed' },
                { value: 'SHOP', text: 'Shop' },
                { value: 'SITE', text: 'Site' },
                { value: 'SL', text: 'Stall' },
                { value: 'STU', text: 'Studio' },
                { value: 'SE', text: 'Suite' },
                { value: 'TNHS', text: 'Townhouse' },
                { value: 'U', text: 'Unit' },
                { value: 'VLLA', text: 'Villa' },
                { value: 'WARD', text: 'Ward' },
                { value: 'WE', text: 'Warehouse' },
            ],
            floor_types: [
                { value: null, text: 'N/A' },
                { value: 'B', text: 'Basement' },
                { value: 'FL', text: 'Floor' },
                { value: 'G', text: 'Ground' },
                { value: 'L', text: 'Level' },
                { value: 'LG', text: 'Lower Ground' },
                { value: 'M', text: 'Mezzanine' },
                { value: 'UG', text: 'Upper Ground' },
            ],
            street_types: [
                { value: null, text: 'N/A' },
                { value: 'ALLY', text: 'Alley' },
                { value: 'ARC', text: 'Arcade' },
                { value: 'AVE', text: 'Avenue' },
                { value: 'BVD', text: 'Boulevard' },
                { value: 'BYPA', text: 'Bypass' },
                { value: 'CCT', text: 'Circuit' },
                { value: 'CL', text: 'Close' },
                { value: 'CRN', text: 'Corner' },
                { value: 'CT', text: 'Court' },
                { value: 'CRES', text: 'Crescent' },
                { value: 'CDS', text: 'Cul-de-sac' },
                { value: 'DR', text: 'Drive' },
                { value: 'ESP', text: 'Esplanade' },
                { value: 'GRN', text: 'Green' },
                { value: 'GR', text: 'Grove' },
                { value: 'HWY', text: 'Highway' },
                { value: 'JNC', text: 'Junction' },
                { value: 'LANE', text: 'Lane' },
                { value: 'LINK', text: 'Link' },
                { value: 'MEWS', text: 'Mews' },
                { value: 'PDE', text: 'Parade' },
                { value: 'PL', text: 'Place' },
                { value: 'RDGE', text: 'Ridge' },
                { value: 'RD', text: 'Road' },
                { value: 'SQ', text: 'Square' },
                { value: 'ST', text: 'Street' },
                { value: 'TCE', text: 'Terrace' },
                { value: 'WAY', text: 'Way' },
            ],
            /**
             * If we are ready a netsip address some of its street abbrs are not the same.
             * This is conversion chart we use to convert detsip to something we can use.
             */
            street_types_netsip_convert: {
                "AV":"AVE"
            },
            street_suffixes: [
                { value: null, text: 'N/A' },
                { value: 'CN', text: 'Central' },
                { value: 'E', text: 'East' },
                { value: 'EX', text: 'Extension' },
                { value: 'LR', text: 'Lower' },
                { value: 'N', text: 'North' },
                { value: 'NE', text: 'North East' },
                { value: 'NW', text: 'North West' },
                { value: 'S', text: 'South' },
                { value: 'SE', text: 'South East' },
                { value: 'SW', text: 'South West' },
                { value: 'UP', text: 'Upper' },
                { value: 'W', text: 'West' },
            ],
            states: [
                { value: 'NSW', text: 'New South Wales' },
                { value: 'VIC', text: 'Victoria' },
                { value: 'QLD', text: 'Queensland' },
                { value: 'SA', text: 'South Australia' },
                { value: 'WA', text: 'Western Australia' },
                { value: 'TAS', text: 'Tasmania' },
                { value: 'ACT', text: 'Australian Capital Territory' },
                { value: 'NT', text: 'Northern Territory' },
            ],
        },

        kinetixAddresses:{
            unitTypes:[
                { value: '', text: '' },
                { value: 'APT', text: 'Apartment' },
                { value: 'BLDG', text: 'Building' },
                { value: 'FLAT', text: 'Flat' },
                { value: 'HSE', text: 'House' },
                { value: 'LOT', text: 'Lot' },
                { value: 'ROOM', text: 'Room' },
                { value: 'SE', text: 'Suite' },
                { value: 'SHOP', text: 'Shop' },
                { value: 'UNIT', text: 'Unit' },
                { value: '------', text: '------' },
                { value: 'ANT', text: 'Antenna' },
                { value: 'ATM', text: 'Automated Teller Machine' },
                { value: 'BBQ', text: 'Barbecue' },
                { value: 'BLCK', text: 'Block' },
                { value: 'BNGW', text: 'Bungalow' },
                { value: 'BTSD', text: 'Boatshed' },
                { value: 'CAGE', text: 'Cage' },
                { value: 'CARP', text: 'Carpark' },
                { value: 'CARS', text: 'Carspace' },
                { value: 'CLUB', text: 'Club' },
                { value: 'COOL', text: 'Coolroom' },
                { value: 'CTGE', text: 'Cottage' },
                { value: 'DUPL', text: 'Duplex' },
                { value: 'FCTY', text: 'Factory' },
                { value: 'GRGE', text: 'Garage' },
                { value: 'HALL', text: 'Hall' },
                { value: 'KSK', text: 'Kiosk' },
                { value: 'LBBY', text: 'Lobby' },
                { value: 'LOFT', text: 'Loft' },
                { value: 'LSE', text: 'Lease' },
                { value: 'MBTH', text: 'Marine Berth' },
                { value: 'MSNT', text: 'Maisonette' },
                { value: 'NBS', text: 'Non Building Structure' },
                { value: 'OFFC', text: 'Office' },
                { value: 'PTHS', text: 'Penthouse' },
                { value: 'REAR', text: 'Rear' },
                { value: 'RESV', text: 'Reserve' },
                { value: 'SEC', text: 'Section' },
                { value: 'SHED', text: 'Shed' },
                { value: 'SHRM', text: 'Showroom' },
                { value: 'SIGN', text: 'Sign' },
                { value: 'SITE', text: 'Site' },
                { value: 'STLL', text: 'Stall' },
                { value: 'STOR', text: 'Store' },
                { value: 'STR', text: 'Strata Unit' },
                { value: 'STU', text: 'Studio' },
                { value: 'SUBS', text: 'Substation' },
                { value: 'TNCY', text: 'Tenancy' },
                { value: 'TNHS', text: 'Townhouse' },
                { value: 'TWR', text: 'Tower' },
                { value: 'VLLA', text: 'Villa' },
                { value: 'VLT', text: 'Vault' },
                { value: 'WARD', text: 'Ward' },
                { value: 'WHSE', text: 'Warehouse' },
                { value: 'WKSH', text: 'Workshop' },
            ],
            roadTypes:[
                {"value": "", "text": ""},
                {"value": "AV", "text": "Avenue"},
                {"value": "DR", "text": "Drive"},
                {"value": "RD", "text": "Road"},
                {"value": "ST", "text": "Street"},
                {"value": "------", "text": "------"},
                {"value": "ACCS", "text": "Access"},
                {"value": "ACRE", "text": "Acre"},
                {"value": "AWLK", "text": "Airwalk"},
                {"value": "ALLY", "text": "Alley"},
                {"value": "ALWY", "text": "Alleyway"},
                {"value": "AMBL", "text": "Amble"},
                {"value": "APP", "text": "Approach"},
                {"value": "ARC", "text": "Arcade"},
                {"value": "ARTL", "text": "Arterial"},
                {"value": "ARTY", "text": "Artery"},
                {"value": "BA", "text": "Banan"},
                {"value": "BANK", "text": "Bank"},
                {"value": "BAY", "text": "Bay"},
                {"value": "BCH", "text": "Beach"},
                {"value": "BDGE", "text": "Bridge"},
                {"value": "BDWY", "text": "Broadway"},
                {"value": "BEND", "text": "Bend"},
                {"value": "BOWL", "text": "Bowl"},
                {"value": "BR", "text": "Brace"},
                {"value": "BRAE", "text": "Brae"},
                {"value": "BRAN", "text": "Branch"},
                {"value": "BRDWLK", "text": "Broadwalk"},
                {"value": "BRET", "text": "Brett"},
                {"value": "BRK", "text": "Break"},
                {"value": "BROW", "text": "Brow"},
                {"value": "BSWY", "text": "Busway"},
                {"value": "BULL", "text": "Bull"},
                {"value": "BVD", "text": "Boulevard"},
                {"value": "BVDE", "text": "Boulevarde"},
                {"value": "BWLK", "text": "Boardwalk"},
                {"value": "BYPA", "text": "Bypass"},
                {"value": "BYWY", "text": "Byway"},
                {"value": "CCT", "text": "Circuit"},
                {"value": "CH", "text": "Chase"},
                {"value": "CIR", "text": "Circle"},
                {"value": "CL", "text": "Close"},
                {"value": "CLDE", "text": "Colonnade"},
                {"value": "CLR", "text": "Cluster"},
                {"value": "CLT", "text": "Circlet"},
                {"value": "CMMN", "text": "Common"},
                {"value": "CMMNS", "text": "Commons"},
                {"value": "CNCD", "text": "Concord"},
                {"value": "CNR", "text": "Corner"},
                {"value": "CNTN", "text": "Connection"},
                {"value": "CNWY", "text": "Centreway"},
                {"value": "CON", "text": "Concourse"},
                {"value": "COVE", "text": "Cove"},
                {"value": "CPS", "text": "Copse"},
                {"value": "CR", "text": "Crescent"},
                {"value": "CRCS", "text": "Circus"},
                {"value": "CRF", "text": "Crief"},
                {"value": "CRK", "text": "Crook"},
                {"value": "CRSE", "text": "Course"},
                {"value": "CRSG", "text": "Crossing"},
                {"value": "CRSS", "text": "Cross"},
                {"value": "CRST", "text": "Crest"},
                {"value": "CSAC", "text": "Cul-De-Sac"},
                {"value": "CSO", "text": "Corso"},
                {"value": "CSWY", "text": "Causeway"},
                {"value": "CT", "text": "Court"},
                {"value": "CTR", "text": "Centre"},
                {"value": "CTYD", "text": "Courtyard"},
                {"value": "CUT", "text": "Cut"},
                {"value": "CUTT", "text": "Cutting"},
                {"value": "CUWY", "text": "Cruiseway"},
                {"value": "DALE", "text": "Dale"},
                {"value": "DASH", "text": "Dash"},
                {"value": "DE", "text": "Deviation"},
                {"value": "DELL", "text": "Dell"},
                {"value": "DENE", "text": "Dene"},
                {"value": "DIP", "text": "Dip"},
                {"value": "DIV", "text": "Divide"},
                {"value": "DOCK", "text": "Dock"},
                {"value": "DOM", "text": "Domain"},
                {"value": "DOWN", "text": "Down"},
                {"value": "DSTR", "text": "Distributor"},
                {"value": "DVWY", "text": "Driveway"},
                {"value": "DWNS", "text": "Downs"},
                {"value": "EAST", "text": "East"},
                {"value": "EDGE", "text": "Edge"},
                {"value": "ELB", "text": "Elbow"},
                {"value": "END", "text": "End"},
                {"value": "ENT", "text": "Entrance"},
                {"value": "ESMT", "text": "Easement"},
                {"value": "ESP", "text": "Esplanade"},
                {"value": "EST", "text": "Estate"},
                {"value": "EXP", "text": "Expressway"},
                {"value": "EXTN", "text": "Extension"},
                {"value": "FAWY", "text": "Fairway"},
                {"value": "FBRK", "text": "Firebreak"},
                {"value": "FITR", "text": "Firetrail"},
                {"value": "FLAT", "text": "Flat"},
                {"value": "FLNE", "text": "Fireline"},
                {"value": "FLTS", "text": "Flats"},
                {"value": "FOLW", "text": "Follow"},
                {"value": "FORD", "text": "Ford"},
                {"value": "FORK", "text": "Fork"},
                {"value": "FORM", "text": "Formation"},
                {"value": "FRNT", "text": "Front"},
                {"value": "FRTG", "text": "Frontage"},
                {"value": "FSHR", "text": "Foreshore"},
                {"value": "FTRK", "text": "Firetrack"},
                {"value": "FTWY", "text": "Footway"},
                {"value": "FWY", "text": "Freeway"},
                {"value": "GAP", "text": "Gap"},
                {"value": "GDN", "text": "Garden"},
                {"value": "GDNS", "text": "Gardens"},
                {"value": "GLDE", "text": "Glade"},
                {"value": "GLEN", "text": "Glen"},
                {"value": "GLY", "text": "Gully"},
                {"value": "GR", "text": "Grove"},
                {"value": "GRA", "text": "Grange"},
                {"value": "GRN", "text": "Green"},
                {"value": "GTE", "text": "Gate"},
                {"value": "GWY", "text": "Gateway"},
                {"value": "HIKE", "text": "Hike"},
                {"value": "HILL", "text": "Hill"},
                {"value": "HILLS", "text": "Hills"},
                {"value": "HIRD", "text": "Highroad"},
                {"value": "HLLW", "text": "Hollow"},
                {"value": "HRBR", "text": "Harbour"},
                {"value": "HTH", "text": "Heath"},
                {"value": "HTS", "text": "Heights"},
                {"value": "HUB", "text": "Hub"},
                {"value": "HVN", "text": "Haven"},
                {"value": "HWY", "text": "Highway"},
                {"value": "ID", "text": "Island"},
                {"value": "INLT", "text": "Inlet"},
                {"value": "INTG", "text": "Interchange"},
                {"value": "JNC", "text": "Junction"},
                {"value": "KEY", "text": "Key"},
                {"value": "KEYS", "text": "Keys"},
                {"value": "KNOL", "text": "Knoll"},
                {"value": "LADR", "text": "Ladder"},
                {"value": "LANE", "text": "Lane"},
                {"value": "LDG", "text": "Landing"},
                {"value": "LEAD", "text": "Lead"},
                {"value": "LEDR", "text": "Leader"},
                {"value": "LINE", "text": "Line"},
                {"value": "LINK", "text": "Link"},
                {"value": "LKT", "text": "Lookout"},
                {"value": "LNWY", "text": "Laneway"},
                {"value": "LOOP", "text": "Loop"},
                {"value": "LYNN", "text": "Lynne"},
                {"value": "MALL", "text": "Mall"},
                {"value": "MANR", "text": "Manor"},
                {"value": "MART", "text": "Mart"},
                {"value": "MEAD", "text": "Mead"},
                {"value": "MEW", "text": "Mew"},
                {"value": "MEWS", "text": "Mews"},
                {"value": "MILE", "text": "Mile"},
                {"value": "MNDR", "text": "Meander"},
                {"value": "MTWY", "text": "Motorway"},
                {"value": "MZ", "text": "Maze"},
                {"value": "NOOK", "text": "Nook"},
                {"value": "NTH", "text": "North"},
                {"value": "NULL", "text": "Null"},
                {"value": "OTLK", "text": "Outlook"},
                {"value": "OTLT", "text": "Outlet"},
                {"value": "OVAL", "text": "Oval"},
                {"value": "PARK", "text": "Park"},
                {"value": "PART", "text": "Part"},
                {"value": "PASS", "text": "Pass"},
                {"value": "PATH", "text": "Path"},
                {"value": "PDE", "text": "Parade"},
                {"value": "PIAZ", "text": "Piazza"},
                {"value": "PKT", "text": "Pocket"},
                {"value": "PL", "text": "Place"},
                {"value": "PLMS", "text": "Palms"},
                {"value": "PLZA", "text": "Plaza"},
                {"value": "PNT", "text": "Point"},
                {"value": "PORT", "text": "Port"},
                {"value": "PRDS", "text": "Paradise"},
                {"value": "PREC", "text": "Precinct"},
                {"value": "PROM", "text": "Promenade"},
                {"value": "PRST", "text": "Pursuit"},
                {"value": "PSGE", "text": "Passage"},
                {"value": "PSLA", "text": "Peninsula"},
                {"value": "PWAY", "text": "Pathway"},
                {"value": "PWY", "text": "Parkway"},
                {"value": "QDRT", "text": "Quadrant"},
                {"value": "QUAD", "text": "Quad"},
                {"value": "QY", "text": "Quay"},
                {"value": "QYS", "text": "Quays"},
                {"value": "RAMP", "text": "Ramp"},
                {"value": "RCH", "text": "Reach"},
                {"value": "RDGE", "text": "Ridge"},
                {"value": "RDS", "text": "Roads"},
                {"value": "RDWY", "text": "Roadway"},
                {"value": "REEF", "text": "Reef"},
                {"value": "RES", "text": "Reserve"},
                {"value": "REST", "text": "Rest"},
                {"value": "RIDE", "text": "Ride"},
                {"value": "RING", "text": "Ring"},
                {"value": "RISE", "text": "Rise"},
                {"value": "RMBL", "text": "Ramble"},
                {"value": "RND", "text": "Round"},
                {"value": "RNGE", "text": "Range"},
                {"value": "ROFW", "text": "Right Of Way"},
                {"value": "ROW", "text": "Row"},
                {"value": "ROWE", "text": "Rowe"},
                {"value": "RSNG", "text": "Rising"},
                {"value": "RTE", "text": "Route"},
                {"value": "RTN", "text": "Return"},
                {"value": "RTT", "text": "Retreat"},
                {"value": "RTY", "text": "Rotary"},
                {"value": "RUE", "text": "Rue"},
                {"value": "RUN", "text": "Run"},
                {"value": "RVR", "text": "River"},
                {"value": "SBWY", "text": "Subway"},
                {"value": "SHUN", "text": "Shunt"},
                {"value": "SKLN", "text": "Skyline"},
                {"value": "SLPE", "text": "Slope"},
                {"value": "SPUR", "text": "Spur"},
                {"value": "SQ", "text": "Square"},
                {"value": "STAI", "text": "Strait"},
                {"value": "STH", "text": "South"},
                {"value": "STPS", "text": "Steps"},
                {"value": "STRA", "text": "Strand"},
                {"value": "STRP", "text": "Strip"},
                {"value": "STRT", "text": "Straight"},
                {"value": "SVWY", "text": "Serviceway"},
                {"value": "TARN", "text": "Tarn"},
                {"value": "TCE", "text": "Terrace"},
                {"value": "THFR", "text": "Thoroughfare"},
                {"value": "THRU", "text": "Throughway"},
                {"value": "TKWY", "text": "Trunkway"},
                {"value": "TLWY", "text": "Tollway"},
                {"value": "TMWY", "text": "Tramway"},
                {"value": "TOP", "text": "Top"},
                {"value": "TOR", "text": "Tor"},
                {"value": "TRK", "text": "Track"},
                {"value": "TRL", "text": "Trail"},
                {"value": "TUNL", "text": "Tunnel"},
                {"value": "TURN", "text": "Turn"},
                {"value": "TVSE", "text": "Traverse"},
                {"value": "TWIST", "text": "Twist"},
                {"value": "UPAS", "text": "Underpass"},
                {"value": "VALE", "text": "Vale"},
                {"value": "VERGE", "text": "Verge"},
                {"value": "VIAD", "text": "Viaduct"},
                {"value": "VIEW", "text": "View"},
                {"value": "VLGE", "text": "Village"},
                {"value": "VLLA", "text": "Villa"},
                {"value": "VLLS", "text": "Villas"},
                {"value": "VLLY", "text": "Valley"},
                {"value": "VSTA", "text": "Vista"},
                {"value": "VUE", "text": "Vue"},
                {"value": "VWS", "text": "Views"},
                {"value": "WADE", "text": "Wade"},
                {"value": "WALK", "text": "Walk"},
                {"value": "WAY", "text": "Way"},
                {"value": "WD", "text": "Wood"},
                {"value": "WDS", "text": "Woods"},
                {"value": "WEST", "text": "West"},
                {"value": "WHRF", "text": "Wharf"},
                {"value": "WKWY", "text": "Walkway"},
                {"value": "WTRS", "text": "Waters"},
                {"value": "WTWY", "text": "Waterway"},
                {"value": "YARD", "text": "Yard"}
            ]
        }
    }),
};
