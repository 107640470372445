<template>
  <div>
    <v-card
      :loading="loading"
      :disabled="loading"
      height="100%"
      class="flexcard"
      elevation="4"
    >
      <v-card
        :loading="loading"
        :disabled="loading"
        height="100%"
        class="flexcard"
        elevation="4"
        :color="statusHexColor(issueData.state)"
        dark
        @click="openIssue"
      >
          <v-card-text class="grow body-1" >
            <div class="d-flex align-center justify-space-between" style="color:#ffffff">
              <div class="d-flex align-center">
                <span style="font-size: 1.1em;">{{ issueData.title }}</span>
              </div>
              <span class="text-right">{{ capitalizeWords(issueData.state) }}</span>
            </div>
            <div class="d-flex align-center justify-space-between" style="color:#ffffff">
              <div class="d-flex align-center">
                <span style="font-size: 0.8em;">Last updated {{ issueData.time_difference_string }}, Opened on {{ issueData.source_updated_at_string}}</span>
              </div>
              <v-icon large v-if="!toggleUpdate">mdi-chevron-down</v-icon>
              <v-icon large v-if="toggleUpdate">mdi-chevron-up</v-icon>
            </div>
          </v-card-text>
      </v-card>
      <div v-for="(updateItem, index) in issueData.updates" :key="updateItem.id">
        <v-slide-y-transition>
          <div v-if="toggleUpdate === true">
            <v-divider class="ml-4 mr-4" v-if="issueData.updates.length > 1 && index != 0"/>
            <v-card-text class="grow body-1">
              <span>
                <strong :class="'update_status_text_color_'+updateItem.state">{{capitalizeWords(updateItem.state)}}</strong>
                - <span class="font-weight-normal" style="font-size: 0.8em; color:#878787">Posted {{ issueData.time_difference_string }}</span><br />
                {{updateItem.text}}
              </span>
            </v-card-text>
          </div>
        </v-slide-y-transition>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
    name: 'StatusIssueBlockCard',
    data: () => ({
      busy:false,
      toggleUpdate: false,
      services: [
        { name: 'API Gateway', status: 'Operational' },
        { name: 'Database', status: 'Maintenance' },
        { name: 'Auth Service', status: 'Operational' },
        // add more services as needed
      ],
    }),
    methods: {
      openIssue(){
        if(this.toggleUpdate){
          this.toggleUpdate = false;
        }else{
          this.toggleUpdate = true;
        }
      },
      statusHexColor(status) {
        if(status == 'investigating'){
          return '#d13943';
        }else if(status == 'operational'){
          return '#2fcc66';
        }else if(status == 'identified'){
          return '#ff8f00';
        }else if(status == 'maintenance'){
          return '#399dd1';
        }else if(status == 'monitoring'){
          return '#399dd1';
        }else if(status == 'down'){
          return '#d13943';
        }else if(status == 'resolved'){
          return '#2fcc66';
        }
      },
      capitalizeWords(value) {
        if (!value) return '';
        return value.replace(/\b\w/g, (l) => l.toUpperCase());
      }
    },
    props: {
        loading: {
          type: Boolean,
          default: false,
        },
        issueData:{
          type: Object,
          default:null,
        }
    },
    computed:{
      isBusy: function () {
        return this.loading || this.busy;
      }
    },
};
</script>
<style scoped>
.update_status_text_color_investigating{
  color:#e74c3c;
}
.update_status_text_color_resolved{
  color:#2fcc66;
}
.update_status_text_color_monitoring{
  color:#399dd1;
}
</style>