<template>
    <v-row dense>
        <v-col cols="12" lg="6">
           <SiteQualificationCard :loading="partnerLoading" :partner-data="partnerData" />
        </v-col>
    </v-row>
</template>

<script>
import partnerDataMixin from '../../mixins/partnerDataMixin';
import SiteQualificationCard from "../../components/cards/NBN/SiteQualificationCard";
export default {
    name: 'PartnerNBNSearch',
    mixins: [partnerDataMixin],
    components: {
      SiteQualificationCard,
    },
};
</script>
