<template>
    <v-chip :small="small" :color="color" :text-color="textColor" label>
        {{ !!data ? data.name : !commissionPlanData ? 'Loading...' : 'None' }}
    </v-chip>
</template>

<script>
import commissionPlanDataMixin from '../../../mixins/commissionPlanDataMixin';

export default {
    name: 'CommissionPlan',
    mixins: [commissionPlanDataMixin],
    props: {
        commissionPlan: {
            type: Number,
            default: null,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        data() {
            let plan = this.commissionPlanData;
            if (plan && Array.isArray(plan)) {
                return plan.find(f => f.i_commission_plan === this.commissionPlan);
            }
            return null;
        },
        color: function () {
            switch (this.commissionPlan) {
                case 10:
                    return '#b9f2ff'; // diamond
                case 11:
                    return '#cd7f32'; // bronze
                case 8:
                    return '#bec2cb'; // silver
                case 9:
                    return '#d4af37'; // gold
                case 7:
                    return '#e5e4e2'; // platinum
                case 12:
                    return 'indigo'; // custom
                default:
                    return null;
            }
        },
        textColor: function () {
            switch (this.commissionPlan) {
                case 10:
                    return '#555555';
                case 11:
                    return '#ffffff';
                case 8:
                    return '#212121';
                case 9:
                    return '#ffffff';
                case 7:
                    return '#555555';
                default:
                    return null;
            }
        },
    },
};
</script>
