import apiMixin from './apiMixin';
export default {
    mixins: [apiMixin],
    methods: {
        async getCommissionPlans() {
            this.$store.commit('loading', { name: 'commission_plans', data: true });
            const response = await this.Api.send('post', (this.$store.state.user.isAdmin ? 'partners' : 'partner') + '/commission_plans')
            this.$store.commit('data', {
                name: 'commission_plans',
                data: response.success ? response.data : [],
            });
            this.$store.commit('loading', { name: 'commission_plans', data: false });
        },
    },
    computed: {
        commissionPlanData() {
            return !!this.$store.state.data.commission_plans ? this.$store.state.data.commission_plans : null;
        }
    },
    created() {
        if (!this.$store.state.data.commission_plans && !this.$store.state.loading.commission_plans) this.getCommissionPlans();
    }
}