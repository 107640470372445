<template>
    <BasicCard :loading="loading" title="Partner Commissions">
        <EditPartnerCommsPlan
            v-if="data"
            key="editPartnerComms"
            v-model="showDialog"
            :data="data"
        />

        <p class="display-1 text--primary">
            <Currency :value="data.balance" />
        </p>

        <p class="subtile-1">
            Commission Plan:
            <CommissionPlan v-if="!!data" :commission-plan="data.i_commission_plan" class="ml-2 mb-1" />
            <span v-else> Loading...</span>
        </p>

        <p class="caption">
            For more information regarding commissions please contact Breeze
            Connect Support.
        </p>

        <Alert
            type="error"
            message="No commission plan set!"
            v-if="
                $store.state.user.user_type === 'admin' &&
                !data.i_commission_plan
            "
        />

        <template v-slot:actions>
            <v-btn disabled text @click="showDialog = true" v-if="$store.state.user.isAdmin"><v-icon v-html="'mdi-pencil'" small left/> Edit</v-btn>
            <v-btn text :to="{ name: $store.state.user.user_type === 'admin' ? 'AdminPartnerCommissions' : 'PartnerCommissions' }"> History </v-btn>
        </template>
    </BasicCard>
</template>

<script>
import Alert from '@/components/pieces/Alert';
import EditPartnerCommsPlan from '../../dialogs/Partners/EditPartnerCommsPlan';
import Currency from '../../pieces/Currency';
import BasicCard from '../templates/BasicCard';
import CommissionPlan from '../../pieces/Partner/CommissionPlan';
export default {
    name: 'PartnerCommsCard',
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    components: {
        CommissionPlan,
        BasicCard,
        Currency,
        EditPartnerCommsPlan,
        Alert,
    },
};
</script>
