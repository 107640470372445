<template>
    <v-row dense>
        <v-col cols="12">
            <CustomerListCard
                title="Customer List"
                :loading="partnerLoading"
                :rep-id="
                    this.$route.params.partnerId
                        ? parseInt(this.$route.params.partnerId)
                        : null
                "
                :filter="filter"
                :limit="filter.limit"
                :page="filter.page"
                @update="updateFilter"
                filter-params
                show-new-customer
                show-filters
            />
        </v-col>
      <FloatingButton v-if="partnerData" tooltip="New Customer" icon="mdi-account-plus" @click="showNewCustomerAccountType=true" :loading="partnerLoading" />
      <NewCustomerAccountTypeDialog  v-if="partnerData" v-model="showNewCustomerAccountType" @type-selected="function(type){customerAccountType=type; showNewCustomer=true;}"/>
      <NewCustomerDialog v-if="partnerData" v-model="showNewCustomer" :rep-id="partnerData.i_rep" :customer-account-type="customerAccountType" @customer="goToCustomer" />
    </v-row>
</template>

<script>
import CustomerListCard from '../../components/cards/Customer/CustomerListCard';
import filterMixin from '../../mixins/filterMixin';
import partnerDataMixin from '../../mixins/partnerDataMixin';
import FloatingButton from '../../components/pieces/FloatingButton';
import NewCustomerDialog from '../../components/dialogs/Customer/NewCustomerDialog';
import NewCustomerAccountTypeDialog from "../../components/dialogs/Customer/NewCustomerAccountTypeDialog";
export default {
    name: 'PartnerCustomers',
    components: {
        NewCustomerAccountTypeDialog,
        NewCustomerDialog,
        FloatingButton,
        CustomerListCard,
    },
    methods: {
        goToCustomer(id) {
          this.showNewCustomer = false;
          this.showNewCustomerAccountType = false;
          this.$router.push({ name: 'CustomerSummary', params: { customerId: id }});
        }
    },
    mixins: [filterMixin, partnerDataMixin],
    data: () => ({
        filter: {
            limit: 10,
            page: 1,
            status: null,
            name: null,
            accountType: null,
        },
        showNewCustomer: false,
        showNewCustomerAccountType:false,
        //customerAccountType:'n/a'
        customerAccountType:'sip-trunk'
    }),
    beforeRouteLeave(to, from, next){
        return (this.showNewCustomer) ? next(false) : next();
    }
};
</script>
