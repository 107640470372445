var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!!_vm.data)?_c('span',[(_vm.data.building_type)?_c('span',[_vm._v(_vm._s(_vm.addressData.building_types.find( function (ref) {
	var value = ref.value;

	return value === _vm.data.building_type;
}).text)+" "+_vm._s(_vm.data.building_number)),_c('span',{domProps:{"innerHTML":_vm._s(_vm.multiline ? '<br/>' : ', ')}})]):_vm._e(),(_vm.data.floor_type)?_c('span',[_vm._v(_vm._s(_vm.addressData.floor_types.find( function (ref) {
	var value = ref.value;

	return value === _vm.data.floor_type;
}).text)),(_vm.data.floor_number)?_c('span',[_vm._v(" "+_vm._s(_vm.data.floor_number))]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.multiline ? '<br/>' : ', ')}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.data.street_number)+" "+_vm._s(_vm.data.street_name)),(_vm.data.street_type)?_c('span',[_vm._v(" "+_vm._s(_vm.addressData.street_types.find( function (ref) {
	var value = ref.value;

	return value === _vm.data.street_type;
}).text))]):_vm._e(),(_vm.data.street_suffix)?_c('span',[_vm._v(" "+_vm._s(_vm.addressData.street_suffixes.find( function (ref) {
	var value = ref.value;

	return value === _vm.data.street_suffix;
}).text))]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.multiline ? '<br/>' : ', ')}}),_vm._v(" "+_vm._s(_vm.data.suburb)+" "+_vm._s(_vm.data.state)+" "+_vm._s(_vm.data.postcode)+" ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }