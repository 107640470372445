<template>
  <v-form v-model="valid">
    <v-text-field
        v-model="input"
        :dense="dense"
        :outlined="outlined"
        :hide-details="hideDetails"
        :type="show ? 'text' : 'password'"
        :label="label"
        :hint="hint"
        :counter="32"
        max-length="32"
        :error-messages="errorMessages"
        :rules="[
            (v) => !!v || 'Password is required',
            (v) =>
                /[A-Z]/.test(v) ||
                'Password must contain at least 1 uppercase letter',
            (v) =>
                /[a-z]/.test(v) ||
                'Password must contain at least 1 lowercase letter',
            (v) =>
                /\d/.test(v) ||
                'Password must contain at least 1 number',
            (v) =>
                /.{8}/.test(v) ||
                'Password must be at least 8 characters long',
            (v) =>
                !!v && v.length <= 32 ||
                'Password must be 32 characters or less',

        ]"
        class="password-input"
    >
      <template v-slot:append>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs}">
            <v-btn
              v-on="on"
              v-bind="attrs"
              v-if="autoGenerate"
              icon
              class="action-button"
              @click="generatePassword"
            >
              <v-icon v-html="'mdi-refresh'" />
            </v-btn>
          </template>
          <span>Generate new password</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs}">
            <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                class="action-button"
                @click="show=!show"
            >
              <v-icon v-html="show ? 'mdi-eye-off' : 'mdi-eye'" />
            </v-btn>
          </template>
          <span v-text="(show ? 'Hide' : 'Show') + ' password'" />
        </v-tooltip>
      </template>
    </v-text-field>
  </v-form>
</template>

<script>
export default {
    name: 'PasswordField',
    data: () => ({
        input: null,
        show: false,
        valid: false,
    }),
    props: {
        value: {
            type: String,
            default: null,
        },
        autoGenerate: {
            type: Boolean,
            default: false
        },
      outlined: {
        type: Boolean,
        default: false
      },
      dense: {
        type: Boolean,
        default: false
      },
      hideDetails: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: null
      },
      hint: {
        type: String,
        default: null
      },
      length: {
        type: Number,
        default: 12
      },
      errorMessages: {
        type: String,
        default: undefined
      }
    },
  methods: {
    generatePassword() {
      var charset = "abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ23456789",
          password = "";
      while (!/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/\d/.test(password)) {
        password = "";
        for (var i = 0, n = charset.length; i < this.length; ++i) {
          password += charset.charAt(Math.floor(Math.random() * n));
        }
      }
      this.input = password;
      //we need to emit input before passwordGenerated so the parent has access to the password when they reveive the event.
      this.$emit('input', password);
      this.$emit('passwordGenerated');
    }
  },
  watch: {
      input(val) {
          if (this.value!==val) this.$emit('input', this.valid ? val : null);
      },
      value(val) {
          if (this.input!==val && !!val) this.input = val;
      },
      valid(val) {
        if (this.input!==this.value) this.$emit('input', this.valid ? this.input : null);
      }
  },
  created() {
      if (this.value) {
        this.input = this.value;
      } else {
        if (this.autoGenerate) this.generatePassword();
      };

  }
};
</script>

<style scoped>
.v-input .action-button {
  margin-top: -6px;
}

</style>