<template>
    <ComplexDialog
        :title="'New '+ customerTypeLabel + ' Customer: Step ' + step"
        v-model="visible"
        :step="step"
        :loading="loading || busy"
        :step-end="6"
        @step-end="finish"
        step-end-text="Go To New Customer"
        @step-back="step--"
        @step-next="next"
        :step-next-disabled="(step === 1 && (!customerType || (customerType===1 && !business) || (customerType===2 && !personal) || !form.id)) ||
             (step === 2 && !contact) ||
             (step === 3 && !address) ||
             (step === 4 && (!form.credit_limit || !creditValid || (form.credit_limit>5000 && !$store.state.user.isAdmin)))"
        :max-width="1000"
        :close-confirmation="!!customerType"
    >
        <v-window v-model="step" touchless>
            <v-window-item :value="1">
                <v-row dense v-if="!repId" class="pb-4">
                    <v-col cols="12">
                        <p class="subtitle-1 text--primary">
                            Partner Details
                        </p>
                        <p>Please choose which partner the new customer will be linked to.</p>
                        <PartnerDropdown
                            :disabled="noRepId"
                            v-model="selectedRepId"
                            label="Partner"
                            hide-details
                            autofocus
                        />
                        <v-checkbox
                            v-model="noRepId"
                            class="px-2"
                            hide-details
                            label="Do not assign a partner"
                        />
                    </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row dense v-if="repId || selectedRepId || noRepId" class="pb-4">
                      <v-col cols="12">
                          <p class="subtitle-1 text--primary">
                              Customer Details
                          </p>
                          <v-radio-group
                              v-model="customerType"
                              label="Customer Type"
                              row
                              hide-details
                          >
                              <v-radio label="Business" :value="1" />
                              <v-radio label="Personal" :value="2" />
                          </v-radio-group>
                      </v-col>
                  </v-row>
                </v-expand-transition>
                <v-expand-transition>
                  <div v-if="(repId || selectedRepId || noRepId) && customerType === 2">
                      <p>Please provide the name, DOB and identification for the individual - a driver's licence or passport number is required.</p>
                      <PersonalDetailsForm
                          v-model="personal"
                          show-search
                      />
                  </div>
                </v-expand-transition>
                <v-expand-transition>
                    <div v-if="(repId || selectedRepId || noRepId) && customerType === 1">
                        <p>Please provide details of the business - a valid ABN/ACN is required. You can pre-fill details by searching an ABN/ACN or business name below.</p>
                        <BusinessDetailsForm
                            v-model="business"
                            show-search
                        />
                    </div>
                </v-expand-transition>
                <v-expand-transition>
                    <div v-if="(customerType===1 && business) || (customerType===2 && personal)">
                        <p class="pb-2">Please enter a display name for the customer - this will be displayed in the portal. The chosen display name must be unique.</p>
                        <CustomerIDField
                            v-model="form.id"
                        />
                    </div>
                </v-expand-transition>
            </v-window-item>
            <v-window-item :value="2" v-if="visible">
                <p class="subtitle-1 text--primary">
                    Customer Contact
                </p>
                <p>Please provide details for the primary contact. Bills and account notifications will be delivered to the nominated email address.</p>
                <ContactDetailsForm
                    v-model="contact"
                    show-bcc
                />
            </v-window-item>
            <v-window-item :value="3" v-if="visible">
                <p class="subtitle-1 text--primary">
                    Customer Address
                </p>
                <p>Please provide the customer's physical address - cannot be a postal address. This should be the main location from which the services will be used. This information will be used in the <a href="https://www.acma.gov.au/give-information-ipnd" target="_blank">Integrated Public Number Database (IPND)</a> which is the Emergency Services Address.</p>
                <AddressDetailsForm
                    v-model="address"
                    show-search
                />
            </v-window-item>
            <v-window-item :value="4" v-if="visible">
                <p class="subtitle-1 text--primary">
                    Customer Account Preferences
                </p>
              <v-expand-transition>
                <v-alert type="warning" v-if="parseFloat(form.credit_limit) > 5000"
                >{{ $store.state.user.isAdmin ? 'Credit limit seems high! Please consider carefully before saving.' : 'You cannot set a credit limit greater than $5,000. If you need a higher limit please contact Breeze Connect Support for assistance.' }}</v-alert
                >
              </v-expand-transition>
              <p>Please set the credit limit for the customer. When the customer’s balance reaches the set credit limit, this customer will be unable to use their services.</p>
              <p>The customer's balance is calculated by adding up all unpaid invoices (including not yet due) plus any call charges from the current billing period that are yet to be invoiced.</p>
              <v-form v-model="creditValid">
                <v-text-field
                    label="Credit Limit"
                    v-model="form.credit_limit"
                    outlined
                    autofocus
                    prefix="$"
                    @keydown.enter.native.prevent
                    :rules="rules.number"
                    style="max-width: 250px;"
                />
              </v-form>
            </v-window-item>

          <v-window-item :value="5" v-if="visible">
                <p class="subtitle-1 text--primary">
                    Review
                </p>
                <p>Please review the below information to ensure it is correct before creating the new customer.</p>
                <v-sheet
                    :color="'grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')"
                    class="pa-4 mb-4"
                    rounded
                    v-if="step===5"
                >
                    <v-row dense>
                        <v-col cols="4" class="font-weight-bold">
                            Customer
                        </v-col>
                        <v-col cols="8">
                            {{form.id}}
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="4" class="font-weight-bold">
                          Customer Account Type
                        </v-col>
                        <v-col cols="8">
                          {{customerTypeLabel}}
                        </v-col>
                        <v-col cols="4" class="font-weight-bold">
                            Customer Type
                        </v-col>
                        <v-col cols="8">
                            <span v-if="customerType===1">Business (<span v-if="business.entity_type===1">Sole Trader / Partnership</span><span v-if="business.entity_type===2">Company / Other</span><span v-if="business.entity_type===3">Trust</span>)</span>
                            <span v-if="customerType===2">Personal</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="customerType===1 && business" dense>
                        <v-col cols="4" class="font-weight-bold">
                            Business Name
                        </v-col>
                        <v-col cols="8">
                            {{business.entity_name}}
                            <span v-if="business.entity_type===3"><span class="text--disabled"> as trustee for</span> {{business.trust_name}} </span>
                            <span v-if="!!business.trading_name"><span class="text--disabled"> trading as</span> {{business.trading_name}}</span>
                        </v-col>
                        <v-col cols="4" class="font-weight-bold">
                            ABN/ACN
                        </v-col>
                        <v-col cols="8">
                            <span v-if="business.entity_type===3">{{business.trust_abn}}</span>
                            <span v-else>{{business.abn_acn}}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="customerType===1 && personal" dense>
                        <v-col cols="4" class="font-weight-bold">
                            Name
                        </v-col>
                        <v-col cols="8">
                            <span v-if="!!personal.title">{{personal.title}} </span>{{personal.firstname}} {{personal.lastame}}
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="4" class="font-weight-bold">
                            Contact Person
                        </v-col>
                        <v-col cols="8">
                            <span v-if="!!contact.title">{{contact.title}} </span>{{contact.firstname}} {{contact.lastname}}
                        </v-col>
                        <v-col cols="4" class="font-weight-bold">
                            Contact Email
                        </v-col>
                        <v-col cols="8">
                            {{contact.email}}<span v-if="!!contact.bcc" class="text--disabled"> (BCC to {{contact.bcc}})</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="!!contact.phone1" dense>
                        <v-col cols="4" class="font-weight-bold">
                            Business Phone
                        </v-col>
                        <v-col cols="8">
                            {{contact.phone1}}
                        </v-col>
                    </v-row>
                    <v-row v-if="!!contact.phone2" dense>
                        <v-col cols="4" class="font-weight-bold">
                            Mobile Phone / SMS
                        </v-col>
                        <v-col cols="8">
                            {{contact.phone2}}
                        </v-col>
                    </v-row>
                    <v-row v-if="address" dense>
                        <v-col cols="4" class="font-weight-bold">
                            Address
                        </v-col>
                        <v-col cols="8">
                            <AddressText :data="address" />
                        </v-col>
                    </v-row>
                  <v-row dense>
                    <v-col cols="4" class="font-weight-bold">
                      Credit Limit
                    </v-col>
                    <v-col cols="8">
                      <Currency :value="parseFloat(form.credit_limit)" hide-tooltip />
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-alert type="info" v-if="contact">
                    A welcome email will be sent to <span class="font-weight-bold">{{contact.email}}</span><span v-if="!!contact.bcc"> (and BCC to {{contact.bcc}})</span>.
                </v-alert>
            </v-window-item>


          <v-window-item :value="6" v-if="visible">
            <v-row no-gutters>
              <v-col cols="12" class="text-center py-4">
                <v-icon size="120" color="success"
                >mdi-check-circle-outline</v-icon
                >
                <p class="text-h6">New customer account created successfully</p>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import PartnerDropdown from '../../pieces/Partner/PartnerDropdown';
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import AddressDetailsForm from '../../pieces/Forms/AddressDetailsForm';
import BusinessDetailsForm from '../../pieces/Forms/BusinessDetailsForm';
import PersonalDetailsForm from '../../pieces/Forms/PersonalDetailsForm';
import ContactDetailsForm from '../../pieces/Forms/ContactDetailsForm';
import CustomerIDField from '../../pieces/Forms/CustomerIDField';
import AddressText from '../../pieces/Address/AddressText';
import Currency from '@/components/pieces/Currency';
export default {
    name: 'NewCustomerDialog',
    components: {
      Currency,
        AddressText,
        CustomerIDField,
        ContactDetailsForm,
        PersonalDetailsForm,
        BusinessDetailsForm,
        AddressDetailsForm,
        PartnerDropdown,
        ComplexDialog,
    },
    mixins: [dialogMixin, apiMixin],
    data: () => ({
        step: 1,
        busy: false,
        creditValid: false,
        selectedRepId: null,
        noRepId: false,
        customerType: 1,
        personal: null,
        business: null,
        address: null,
        contact: null,
        i_customer: null,
        form: {
            i_rep: null,
            id: '',
            credit_limit: ''
        },
      rules: {
        number: [
          (v) => !!v || 'Required',
          (v) =>
              /^\d+(?:\.\d{1,2})?$/i.test(v) ||
              'Enter a valid number',
          (v) => parseFloat(v) !== 0 || 'Cannot be zero',
        ],
      },
    }),
    methods: {
        next: function() {
            if (this.step===5) {
                this.create();
            } else {
                this.step++;
            }
        },
        updateRepId: function (value) {
            this.selectedRepId = value;
        },
        updateNoRep: function (value) {
            this.noSelectedRep = value;
        },
        clearData: function () {
            this.step = 1;
            this.selectedRepId = null;
            this.noSelectedRep = false;
            //this.customerType = null;
            this.personal = null;
            this.business = null;
            this.address = null;
            this.i_customer = null;
            this.form.i_rep = null;
            this.form.id = null;
            this.form.credit_limit = null;
        },
        async create() {
            this.busy = true;
            let params = {
              customer_account_type: this.customerAccountType,
              customer_type: this.customerType,
              entity_info: this.customerType===1 ? this.business : this.personal,
              contact_info: this.contact,
              address_info: this.address,
              i_rep: this.repId ? this.repId : (this.noRepId ? null : this.selectedRepId),
              expecting_i_rep: (this.noRepId ? false : true),
              name: this.form.id,
              credit_limit: this.form.credit_limit,
            };
            if(this.customerType===2){
              if(params.entity_info.id_type == 1){
                delete params.entity_info.passport_country;
                delete params.entity_info.passport_no;
              }else{
                delete params.entity_info.licence_no;
                delete params.entity_info.licence_state;
              }
            }
            const response = await this.Api.send('post', 'customers/create', params);
            if (response.success) {
                this.i_customer = response.data.i_customer;
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                this.step++;
            }
            this.busy = false;
        },
      finish() {
          this.visible = false;
          this.$emit('customer', this.i_customer);
      }
    },
    watch: {
        visible(value) {
            if (!value) {
                this.clearData();
            }else{
              //we validate the customer account type. This should never be inaccurate.
              if(this.customerAccountType != 'sip-trunk' && this.customerAccountType != 'hosted-extension'){
                this.visible = false;
                throw ('Invalid select account type ('+this.customerAccountType+'). Process cannot continue.');
              }
            }
        },
        business: {
            handler(data) {
                this.contact = (data && data.contact) ? data.contact : null
            },
            deep: true
        },
        personal: {
            handler(data) {
                this.contact = (data && data.contact) ? data.contact : null
            },
            deep: true
        },
    },
    props: {
        repId: {
            default: null,
        },
        customerAccountType: {
          required:true,
          type: String
        },
    },
  computed:{
    customerTypeLabel() {
      let ret = 'N/A';
      if(this.customerAccountType == 'sip-trunk'){
        ret = 'SIP Trunk';
      }else if(this.customerAccountType == 'hosted-extension'){
        ret = 'Hosted Extension';
      }
      return ret;
    }
  }
};
</script>

<style scoped></style>
