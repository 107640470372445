export default {
    data: () => ({
        firstLoad: true,
        isLoading: false,
        data: [],
        localFilter: {
        },
    }),
    methods: {
        updatePage(data) {
            this.$emit('update', {page: data});
        },
        updateLimit(data) {
            this.$emit('update', { limit: data, page: 1 })
        },
        update() {
            this.getLocalFilters();
        },
        refreshHandler() {
            this.update();
        },
        updateLocalFilter() {
            this.$emit('update', { ...this.localFilter, ...{ page: 1 } })
        },
        /**
         * Reset the local filters to use the filter values passed in the filter prop.
         * Useful if a specific filters value must be used for all searches.
         */
        getLocalFilters() {
            for (var item in this.localFilter){
                if (this.localFilter[item]!==this.filter[item]){
                    this.localFilter[item] = this.filter[item];
                }
            }
        }
    },
    props: {
        filter: {
            type: Object,
            default: null,
        },
        showFilters: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        loading: function (value) {
            if (!value) this.update();
        },
        filter: {
            handler() {
                this.update();
            },
            deep: true
        },
    },
    created() {
        if (!this.loading) this.update();
    }
};