<template>
    <v-chip
        small
        :color="color"
        :text-color="color === 'green' || color === 'red' ? 'white' : 'black'"
    >
        {{ text }}
    </v-chip>
</template>

<script>
export default {
    name: 'CustomerStatus',
    props: {
        data: {
            default: [],
        },
    },
    computed: {
        text: function () {
            if (
                ((this.data.bill_status === 'S' &&
                    this.data.bill_suspension_delayed === 1) ||
                    this.data.bill_status === 'O') &&
                this.data.balance > this.data.credit_limit
            ) {
                return 'Credit Limit Exceeded';
            }
            if (this.data.blocked==='Y') return 'Blocked';
            switch (this.data.bill_status) {
                case 'D':
                    return 'Pending Termination';
                case 'S':
                    return this.data.bill_suspension_delayed === 1
                        ? 'Suspension Delayed'
                        : 'Suspended';
                case 'C':
                    return 'Closed';
                case 'E':
                    return 'Exported';
                case 'O':
                    return 'Active';
                default:
                    return 'Unknown';
            }
        },
        color: function () {
            if (
                ((this.data.bill_status === 'S' &&
                    this.data.bill_suspension_delayed === 1) ||
                    this.data.bill_status === 'O') &&
                this.data.balance > this.data.credit_limit
            ) {
                return 'red';
            }
            if (this.data.blocked==='Y') return 'red';
            switch (this.data.bill_status) {
                case 'D':
                    return 'orange';
                case 'S':
                    return this.data.bill_suspension_delayed === 1
                        ? 'orange'
                        : 'red';
                case 'O':
                    return 'green';
                default:
                    return null;
            }
        },
    },
};
</script>
