<template>
  <BasicCard :loading="isBusy" noPadding title="Subscribe to notifications">
    <v-card-text>
      <v-form ref="form" v-model="isValid">
            <v-text-field
              label="Email"
              ref="email"
              name="email"
              v-model="form.email"
              type="text"
              :rules="rules.email"
              required
            />

            <v-btn
              :disabled="!isValid"
              class="mr-4"
              @click="subscribe"
            >
              Subscribe
            </v-btn>

      </v-form>
    </v-card-text>
  </BasicCard>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import BasicCard from "../templates/BasicCard.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import apiMixin from "../../../mixins/apiMixin";
export default {
    name: 'StatusSubscriptionBlockCard',
    mixins: [GlobalHelperMixin],
    data: () => ({
        busy:false,
        rules: {
          email: [
            (v) => !!v || 'Required',
            (v) => /^.+@.+\..+$/.test(v) || 'Invalid email',
          ],
        },
        form: {
          email: '',
        },
        isValid: false,
    }),
    components: {
      BasicCard,
    },
    methods: {
      async subscribe() {
        this.busy = true;

        let params = {
          "email":this.form.email
        }

        const response = await this.Api.send('post','/operational-status/subscribe',params);
        this.busy = false;
        if(response.success){
          this.showGlobalSuccessMessage('Email address ('+this.form.email+') is successfully subscribed to status updates.','You should receive an email confirming your subscription.');
          this.reset( );
        }
      },
      reset(status) {
        this.form.email = '';
      }
    },
    props: {
        loading: {
          type: Boolean,
          default: false,
        },
    },
    computed:{
      isBusy: function () {
        return this.loading || this.busy;
      }
    }
};
</script>