<template>
  <div>
    <BasicCard :loading="loading" noPadding :title="statusGroupData.title">
      <v-row>
        <v-col cols="12" class="body-1 text--primary">
          <div v-for="(item, index) in statusGroupData.services" :key="item.name" class="pa-4 d-flex align-center justify-space-between"
                              v-bind:class="{
                        'list-item-background-dark': $vuetify.theme.dark && index % 2 !== 0,
                        'list-item-background-light': !$vuetify.theme.dark && index % 2 !== 0,
                        'list-item-dark': $vuetify.theme.dark,
                        'list-item-light': !$vuetify.theme.dark,
                    }"
          >
            <div class="d-flex align-center">
              <span class=" pr-1">{{ item.name }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                  mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>{{ item.description }}</span>
              </v-tooltip>
            </div>
            <span class="text-right" :style="{ color: '#'+colorSwtich(item.status.color) }">{{ item.status.name }}</span>
          </div>
        </v-col>
      </v-row>
    </BasicCard>
  </div>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import BasicCard from "../templates/BasicCard.vue";
export default {
    name: 'StatusServiceBlockCard',
    data: () => ({
        busy:false,
      services: [
        { name: 'API Gateway', status: 'Operational' },
        { name: 'Database', status: 'Maintenance' },
        { name: 'Auth Service', status: 'Operational' },
        // add more services as needed
      ],
    }),
    components: {
      BasicCard,
    },
    methods: {
      statusColor(status) {
        return {
          'green--text': status === 'Operational',
          'orange--text': status === 'Maintenance',
          'red--text': status === 'Down'
        };
      },
      colorSwtich(color){
        if(color == 'F1C40F'){
          return 'ff8f00';
        }else{
          return color;
        }
      }
    },
    props: {
        loading: {
          type: Boolean,
          default: false,
        },
        statusGroupData:{
          type: Object,
          default:null,
        }
    },
    computed:{
      isBusy: function () {
        return this.loading || this.busy;
      }
    }
};
</script>
<style scoped>
.list-item-background-light {
  background-color: #f6fbff;
}

.list-item-background-dark {
  background-color: #002e52;
}
.list-item-light:not(:last-child) {
  border-bottom: 1px solid #ccc; /* Adjust color and thickness as needed */
}
.list-item-dark:not(:last-child) {
  border-bottom: 1px solid #444444; /* Adjust color and thickness as needed */
}
</style>