<template>
    <v-form v-model="valid" ref="addressForm" v-on:submit.prevent>
        <v-expand-transition>
            <AddressGoogleAutocomplete
                v-if="showSearch && !filled && !manualEntry"
                @selected="addressSelected"
                :show-manual-entry="showManualEntry == false ? false : !manualEntry"
                @manual="manualEntry=true"
            />
        </v-expand-transition>
        <v-fade-transition>
            <v-row dense v-show="((!showSearch || manualEntry) && showManualEntry) || (filled && !valid)" key="subAddress">
                <v-expand-transition>
                    <v-col cols="12" v-show="showSubAddress" key="showSubAddressForm">
                        <v-sheet
                            color="background_accent"
                            class="pa-4 mb-4"
                            rounded
                        >
                            <v-row dense>
                                <v-col cols="10">
                                    <p>Sub-address details<span class="text--disabled"> - only required for sub-address such as 'Unit 3', 'Suite 7, Level 2' or similar</span></p>
                                </v-col>
                                <v-col cols="2" class="text-right">
                                    <v-btn text icon @click="clearSubAddress">
                                        <v-icon v-html="'mdi-close'" />
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="7" sm="3">
                                    <v-select
                                        v-model="address.building_type"
                                        :items="addressData.building_types"
                                        :rules="!!address.building_number ? rules.required : []"
                                        @change="changeBuildingType"
                                        :label="'Building Type' + (!!address.building_number ? ' *' : '')"
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="5" sm="3">
                                    <v-text-field
                                        ref="buildingNumber"
                                        v-model="address.building_number"
                                        :rules="!!address.building_type ? rules.required : []"
                                        :label="'Building No' + (!!address.building_type ? ' *' : '')"
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="7" sm="3">
                                    <v-select
                                            v-model="address.floor_type"
                                            :items="addressData.floor_types"
                                            :rules="!!address.floor_number ? rules.required : []"
                                            @change="changeFloorType"
                                            :label="'Floor Type' + (!!address.floor_number ? ' *' : '')"
                                            outlined
                                    />
                                </v-col>
                                <v-col cols="5" sm="3">
                                    <v-text-field
                                            ref="floorNumber"
                                            v-model="address.floor_number"
                                            label="Floor No"
                                            hint="Optional"
                                            outlined
                                    />
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                </v-expand-transition>
                <v-expand-transition>
                    <v-col cols="12" v-show="!showSubAddress" class="pa-1">
                        <p><a @click="reset">Address Search</a> | <a @click="showSubAddress=true">Add building or floor</a> e.g. 'Unit 3' or 'Suite 7, Level 2'</p>
                    </v-col>
                </v-expand-transition>
                <v-col cols="4" sm="2">
                    <v-text-field
                        v-model="address.street_number"
                        label="Street No *"
                        :rules="rules.street_number"
                        outlined
                    />
                </v-col>
                <v-col cols="8" sm="5" md="6">
                    <v-text-field
                        v-model="address.street_name"
                        label="Street Name *"
                        :rules="rules.street_name"
                        outlined
                    />
                </v-col>
                <v-col cols="6" sm="3" md="2">
                    <v-select
                        v-model="address.street_type"
                        :items="addressData.street_types"
                        label="Street Type"
                        outlined
                    />
                </v-col>
                <v-col cols="6" sm="2">
                    <v-select
                        v-model="address.street_suffix"
                        :items="addressData.street_suffixes"
                        label="Street Suffix"
                        outlined
                    />
                </v-col>
                <v-col cols="12" sm="7">
                    <v-text-field
                        v-model="address.suburb"
                        label="Suburb *"
                        :rules="rules.suburb"
                        outlined
                    />
                </v-col>
                <v-col cols="8" sm="3">
                    <v-select
                        v-model="address.state"
                        :items="addressData.states"
                        label="State *"
                        :rules="rules.required"
                        outlined
                    />
                </v-col>
                <v-col cols="4" sm="2">
                    <v-text-field
                        v-model="address.postcode"
                        label="Postcode *"
                        :rules="rules.postcode"
                        outlined
                    />
                </v-col>
            </v-row>
        </v-fade-transition>
        <v-fade-transition>
            <v-row dense v-if="(filled || manualEntry) && valid" key="subAddress">
                <v-col cols="12">
                    <v-sheet rounded color="background_accent" class="pa-4">
                        <div class="d-flex">
                        <v-icon v-if="!hideTick" color="success" v-text="'mdi-check-circle'" large class="mr-4" />
                        <p class="text-h5 my-0">
                            <AddressText :data="address" />
                        </p>
                        </div>
                    </v-sheet>
                </v-col>
                <v-col cols="12" v-if="!manualEntry">
                    <a @click="reset">Search again</a><a v-if="showManualEntry" class="pl-4" @click="manualEntry=true">Edit this address</a>
                </v-col>
            </v-row>
        </v-fade-transition>
      <SimpleDialog title="Address Error"
                    :okOnly="true"
                    :styledHeader="false"
                    v-model="errorDialog"
                    >
          <p>{{errorDialogMessage}}</p>
      </SimpleDialog>
    </v-form>
</template>

<script>
import addressDataMixin from '../../../mixins/addressDataMixin';
import AddressGoogleAutocomplete from './AddressGoogleAutocomplete';
import AddressText from '../Address/AddressText';
import SimpleDialog from "../../dialogs/templates/SimpleDialog.vue";
const StreetNumberRegex = /^(\d{1,5})([A-Z])?(?:\-(\d{1,5})([A-Z])?)?$/;
const NameRegex = /^[A-Z](?:(?:[A-Za-z -]+)?[A-Za-z])?$/;
export default {
    name: 'AddressDetailsForm',
    components: { SimpleDialog, AddressText, AddressGoogleAutocomplete },
    mixins: [addressDataMixin],
    data: () => ({
        valid: false, //toggle if the address form is valid
        showSubAddress: false,
        manualEntry: false,
        filled: false,
        errorDialog: false,
        errorDialogMessage:null,
        rules: {
            required: [
                v => !!v || 'Required',
            ],
            street_number: [
                v => !!v || 'Required',
                v => !/\s+/.test(v) || 'Cannot contain spaces',
                v => StreetNumberRegex.test(v) || 'Invalid street number',
            ],
            street_name: [
                v => !!v || 'Required',
                v => v.length <= 25 || 'Cannot be more than 25 characters',
                v => NameRegex.test(v) || 'Invalid street name',
            ],
            suburb: [
                v => !!v || 'Required',
                v => v.length <= 40 || 'Cannot be more than 40 characters',
                v => NameRegex.test(v) || 'Invalid suburb name',
            ],
            postcode: [
                v => !!v || 'Required',
                v => /^[0-7][0-9]{3}$/.test(v) || 'Invalid postcode',
            ],
        },
        address: {
            building_type: null,
            building_number: '',
            floor_type: null,
            floor_number: '',
            street_number: '',
            street_name: '',
            street_type: null,
            street_suffix: null,
            suburb: '',
            state: null,
            postcode: ''
        }
    }),
    methods: {
        addressSelected(data) {
            // process address result from google
            this.address.street_number = data.street_number || '';
            this.address.street_name = data.street_name || '';
            this.address.street_type = this.addressData.street_types.some((v) => v.text === data.street_type) ? this.addressData.street_types.find( ({ text }) => text === data.street_type).value : null;
            this.address.street_suffix = this.addressData.street_suffixes.some((v) => v.text === data.street_suffix) ? this.addressData.street_suffixes.find( ({ text }) => text === data.street_suffix).value : null;
            this.address.building_type = data.building_type || null;
            this.showSubAddress = !!data.sub_dwelling_number;
            this.manualEntry = (data.sub_dwelling_number != null && data.sub_dwelling_number != '') && !data.building_type; //if has a dwelling number and no building type
            this.address.building_number = data.sub_dwelling_number || '';
            this.address.floor_type = null;
            this.address.floor_number = '';
            this.address.suburb = data.suburb || '';
            this.address.state = this.addressData.states.some((v) => v.value === data.state) ? this.addressData.states.find((v) => v.value === data.state).value : null;
            this.address.postcode = data.postcode || '';
            this.filled = true;
            if(this.address.street_number == ''){
              /**
               * Note there is an issue where Google does not return a street number.
               * This then causes the validation to fail and the UI to be a malformed state where
               * the Google search input it hidden and not final address is shown.
               *
               * I believe this is going to require some sigificant refacting of this logic to correctly
               * catch this issue and then correct adjust the UI to suit.
               * Mainly because this component has many different ways to use it and we would need a
               * process to set the 'filled' toggle after validation on when it is required.
               * Also logic to cater for a failed validated at this step.
               *
               * In anycase I've decided just to output a popover message to inform the use
               * of the error. As I believe this is an edge case situation.
               */
              this.errorDialogMessage = 'Failed to return street number from the address search. Process cannot continue. Please try manual address entry.';
              this.errorDialog = true;
            }
            this.$nextTick(() => {
                this.$refs.addressForm.validate();
            });
        },
        reset() {
            this.manualEntry = false;
            this.filled = false;
            if(this.defaultShow == 'manual'){
              this.manualEntry = true;
            }
        },
        clearSubAddress() {
            this.address.building_type = '';
            this.address.building_number = '';
            this.address.floor_type = '';
            this.address.floor_number = '';
            this.showSubAddress = false;
        },
        changeBuildingType(value) {
            if (value) this.$refs.buildingNumber.focus();
        },
        changeFloorType(value) {
            if (value) this.$refs.floorNumber.focus();
        }
    },
    watch: {
        address: {
            handler(data) {
              if (this.value!==data) this.$emit('input', this.valid ? data : null );
            },
            deep: true
        },
        valid(value) {
          this.$emit('input', value ? this.address : null );
        },
        'address.street_number'(value) {
            if (!StreetNumberRegex.test(value)) {
                // check if not validating due to lower case letter - if so, make uppercase
                if (value) value = value.toUpperCase();
                if (value !== this.address.street_number) this.address.street_number = value;
            }
        },
        'address.street_name'(value) {
            if (!NameRegex.test(value)) {
                // check if issue is due to first letter not being capital - if so, capitalise first letter
                if (value) value = value.trim().charAt(0).toUpperCase() + value.slice(1);
                if (value !== this.address.street_name) this.address.street_name = value;
            }
        },
        'address.suburb'(value) {
            if (!NameRegex.test(value)) {
                // check if issue is due to first letter not being capital - if so, capitalise first letter
                if (value) value = value.trim().charAt(0).toUpperCase() + value.slice(1);
                if (value !== this.address.suburb) this.address.suburb = value;
            }
        },
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        // Toggle if we want to remove or include the google auto search
        showSearch: {
            type: Boolean,
            default: false
        },
      //toggle to remove or include the manual entry
        showManualEntry: {
          type: Boolean,
          default: true
        },
      // toggle to determine which we show first 'auto' or 'manual'
        defaultShow:{
          type: String,
          default: 'auto',
        },

        hideTick:{ //used to hide the tick icon.
            type: Boolean,
          default: false
        }
    },
    created() {
        if (this.value) this.address = { ...this.address, ...this.value };

        this.reset( );
    }
};
</script>