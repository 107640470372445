<template>
    <v-form v-model="valid" ref="personalForm" v-on:submit.prevent="">
        <v-row dense>
            <v-col cols="12" sm="2">
                <v-combobox
                    v-model="personal.title"
                    label="Title"
                    :items="data.titles"
                    hint="Optional"
                    outlined
                />
            </v-col>
            <v-col cols="12" sm="5">
                <v-text-field
                    v-model="personal.firstname"
                    label="First Name"
                    hint="Required"
                    outlined
                    :rules="rules.required"
                />
            </v-col>
            <v-col cols="12" sm="5">
                <v-text-field
                    v-model="personal.lastname"
                    label="Last Name"
                    hint="Required"
                    outlined
                    :rules="rules.required"
                />
            </v-col>
            <v-col cols="6" sm="3">
                <DatePicker
                    v-model="personal.dob"
                    label="Date of Birth"
                    activePicker="YEAR"
                    :rulesIn="rules.dateOfBirth"
                    :min="allowedDates[0]"
                    :max="allowedDates[(allowedDates.length - 1)]"
                />
            </v-col>
            <v-col cols="6" sm="3">
                <v-select
                    v-model="personal.id_type"
                    label="ID Type"
                    :items="data.idTypes"
                    :rules="rules.required"
                    outlined
                />
            </v-col>

            <v-col cols="6" sm="3" v-if="personal.id_type===1">
                <v-select
                    v-model="personal.licence_state"
                    label="Licence State"
                    :items="data.states"
                    :rules="personal.id_type===1 ? rules.required : []"
                    hint="Optional"
                    outlined
                />
            </v-col>
            <v-col cols="6" sm="3" v-if="personal.id_type===1">
                <v-text-field
                    v-model="personal.licence_no"
                    label="Licence No"
                    hint="Required"
                    outlined
                    :rules="personal.id_type===1 ? rules.licenceNo : []"
                />
            </v-col>
            <v-col cols="6" sm="3" v-if="personal.id_type===2">
                <v-text-field
                    v-model="personal.passport_country"
                    label="Passport Country"
                    hint="Required"
                    outlined
                    :rules="personal.id_type===2 ? rules.PassportCountry : []"
                />
            </v-col>
            <v-col cols="6" sm="3" v-if="personal.id_type===2">
                <v-text-field
                    v-model="personal.passport_no"
                    label="Passport No"
                    hint="Required"
                    outlined
                    :rules="personal.id_type===2 ? rules.PassportNumber : []"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import DatePicker from './DatePicker';
    export default {
        name: 'PersonalDetailsForm',
        components: { DatePicker },
        data: () => ({
            valid: false,
            manualEntry: false,
            showForm: false,
            personal: {
                title: '',
                firstname: '',
                lastname: '',
                dob: '',
                licence_no: '',
                licence_state: '',
                passport_no: '',
                passport_country: '',
                id_type: 1
            },
            rules: {
                required: [
                    v => !!v || 'Required',
                ],
                dateOfBirth: [
                  v => Math.round(new Date(v).getTime()/1000) < (Math.round(new Date().getTime()/1000) - 504576000) || 'Person must be older than 16 years of age.',
                  v => Math.round(new Date(v).getTime()/1000) > (Math.round(new Date().getTime()/1000) - 4730400000) || '150 years of age is too old.',
                ],
                licenceNo: [
                  v => (v && v.length > 4) || 'Licence number is too short',
                  v => (v && v.length < 21)  || 'Licence number is too long',
                ],
                PassportNumber: [
                  v => (v && v.length > 1) || 'Passport number is too short',
                  v => (v && v.length < 21) || 'Passport number is too long',
                ],
                PassportCountry: [
                  v => (v && v.length > 1) || 'Passport country is too short',
                  v => (v && v.length < 91)  || 'Passport country is too long',
                ],
            },
            data: {
                titles: ['Dr', 'Miss', 'Mr', 'Mrs', 'Ms'],
                idTypes: [
                    { value: 1, text: "Driver's Licence" },
                    { value: 2, text: "Passport" },
                ],
                states: [
                    { value: 'NSW', text: 'New South Wales' },
                    { value: 'VIC', text: 'Victoria' },
                    { value: 'QLD', text: 'Queensland' },
                    { value: 'SA', text: 'South Australia' },
                    { value: 'WA', text: 'Western Australia' },
                    { value: 'TAS', text: 'Tasmania' },
                    { value: 'ACT', text: 'Australian Capital Territory' },
                    { value: 'NT', text: 'Northern Territory' },
                ],
            },
        }),
      watch: {
        valid(value) {
          if (!this.dob || !value) {
            this.$emit('input', null);
          }else{
            this.$emit('input', this.personal);
          }
        },
        dob(value){
          if (!this.valid || !value) {
            this.$emit('input', null);
          }else{
            this.$emit('input', this.personal);
          }
        }
      },
      computed: {
        dob: function() {
          return this.personal.dob;
        },
        allowedDates: function( ){
          const currentDate = new Date();
          const validDates = [];

          // Calculate the dates for 18 to 119 years ago
          for (let yearsAgo = 120; yearsAgo >= 17; yearsAgo--) {
            const date = new Date(currentDate);
            date.setFullYear(date.getFullYear() - yearsAgo);
            validDates.push(this.formatDate(date));
            // validDates.push(date.getFullYear());
          }
          return validDates;
        }
      },
      methods:{
        formatDate(date) {
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
          const day = date.getDate().toString().padStart(2, '0');

          return `${year}-${month}-${day} 00:00:00.000`;
        }
      }
    };
</script>

<style scoped>

</style>