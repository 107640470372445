<template>
    <span v-if="portaData != null">
      <span v-if="portaData.baddr1">{{portaData.baddr1}}<span v-html="multiline ? '<br/>' : ', '" /></span>
      <span v-if="portaData.address_line_2">{{portaData.address_line_2}}<span v-html="multiline ? '<br/>' : ', '" /></span>
      {{portaData.city}} {{portaData.state}} {{portaData.zip}}
    </span>
</template>
<script>
    export default {
      /**
       * This caters for formatting of address data passed via the data from porta one.
       */
        name: 'AddressTextPortaData',
        props: {
            portaData: {
                type: Object,
                default: null
            },
            multiline: {
                type: Boolean,
                default: false
            }
        }
    };
</script>