<template>
    <BasicCard
        :loading="isBusy"
        title="NBN Site Qualification"
    >

      <template v-slot:feature>
        <v-chip class="secondary ml-2">New</v-chip>
        <v-icon right x-large>mdi-web</v-icon>
      </template>
      <br />
      <v-tabs
          v-model="tab"
          grow
      >
        <v-tab key="address">
          By Address
        </v-tab>
        <v-tab key="location-id">
          Location ID
        </v-tab>
        <v-tab key="advanced-search">
          Advanced Search
        </v-tab>
      </v-tabs>
      <br /><br />
      <v-tabs-items v-model="tab">
        <v-tab-item key="address">

          <p v-if="partnerData != null">Input address and click '<v-icon style="font-size:1.1rem;">mdi-check</v-icon> Qualify' to check if NBN is available.</p>
          <p v-if="customerData != null">Select or input address and click '<v-icon style="font-size:1.1rem;">mdi-check</v-icon> Qualify' to check if NBN is available. If a connection is possible you may place an NBN order if you wish.</p>
          <p>The your desired address is not appearing in the results you might like to try the 'Advanced Search' tab.</p>

          <ExistingAddressDetailsForm v-if="customerData != null"
                                      :customer-id="customerData == null ? null : customerData.i_customer"
                                      v-model="customerAddressInput"
                                      :hideTick="true"
                                      :showManualEntry="false"
          />

          <AddressDetailsForm v-if="partnerData != null"
                              show-search
                              v-model="partnerAddressInput"
                              :hideTick="true"
                              :showManualEntry="false"
          />

        </v-tab-item>
        <v-tab-item key="location-id">
          <p v-if="partnerData != null">Input location ID and click '<v-icon style="font-size:1.1rem;">mdi-check</v-icon> Qualify' to check if NBN is available.</p>
          <p v-if="customerData != null">Input location ID and click '<v-icon style="font-size:1.1rem;">mdi-check</v-icon> Qualify' to check if NBN is available. If a connection is possible you may place an NBN order if you wish.</p>

          <v-text-field
              v-model="locationID"
              :label="'Location ID'"
              outlined
          />
        </v-tab-item>
        <v-tab-item key="advanced-search">
          <p v-if="partnerData != null">Input address and click '<v-icon style="font-size:1.1rem;">mdi-check</v-icon> Qualify' to check if NBN is available.</p>
          <p v-if="customerData != null">Select or input address and click '<v-icon style="font-size:1.1rem;">mdi-check</v-icon> Qualify' to check if NBN is available. If a connection is possible you may place an NBN order if you wish.</p>
          <p>If you are having trouble trying to find a specific address try the search with variations of the information.</p>
          <p>* mandatory</p>
          <v-form v-model="advanceSearchValid" v-on:submit.prevent>
            <v-row dense>
              <v-col sm="6" cols="12">
                <v-text-field
                    v-model="advanceSearch.lotNumber"
                    :label="'Lot Number'"
                    outlined
                    dense
                    :rules="[rules.numeric]"
                />
              </v-col>
              <v-col sm="6" cols="12">
                <v-text-field
                    v-model="advanceSearch.planNumber"
                    :label="'Plan Number'"
                    outlined
                    dense
                    :rules="[rules.numeric]"
                />
              </v-col>
              <v-col sm="6" cols="12">
                <v-text-field
                    v-model="advanceSearch.unitNumber"
                    :label="'Unit Number'"
                    outlined
                    dense
                    :rules="[rules.numeric]"
                />
              </v-col>
              <v-col sm="6" cols="12">
                <v-select
                    v-model="advanceSearch.unitType"
                    :items="kinetixAddresses.unitTypes"
                    :label="'Unit Type'"
                    outlined
                    dense
                />
              </v-col>
              <v-col sm="6" cols="12">
                <v-text-field
                    v-model="advanceSearch.levelNumber"
                    :label="'Level Number'"
                    outlined
                    dense
                    :rules="[rules.numeric]"
                />
              </v-col>
              <v-col sm="6" cols="12">
                <v-text-field
                    v-model="advanceSearch.levelType"
                    :label="'Level Type'"
                    outlined
                    dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="advanceSearch.siteName"
                    :label="'Site Name'"
                    outlined
                    dense
                />
              </v-col>

              <v-col cols="5">
                <v-text-field
                    v-model="advanceSearch.roadNumberStart"
                    :label="'Road Number Start'"
                    outlined
                    dense
                    :rules="rules.street_number"
                />
              </v-col>
              <v-col cols="2" class="d-flex justify-center pb-8">
                <v-icon>
                  mdi-minus
                </v-icon>
              </v-col>
              <v-col cols="5">
                <v-text-field
                    v-model="advanceSearch.roadNumberFinish"
                    :label="'Road Number Finish'"
                    outlined
                    dense
                    :rules="rules.street_number"
                />
              </v-col>

              <v-col sm="6" cols="12">
                <v-text-field
                    v-model="advanceSearch.roadName"
                    :label="'Road Name *'"
                    outlined
                    dense
                    :rules="rules.street_name"
                />
              </v-col>
              <v-col sm="6" cols="12">
                <v-select
                    v-model="advanceSearch.roadType"
                    :items="kinetixAddresses.roadTypes"
                    :label="'Road Type'"
                    outlined
                    dense
                />
              </v-col>

              <v-col sm="6" cols="12">
                <v-text-field
                    v-model="advanceSearch.townSuburb"
                    :label="'Town/Suburb *'"
                    :rules="rules.suburb"
                    outlined
                    dense
                />
              </v-col>
              <v-col sm="6" cols="12">
                <v-select
                    v-model="advanceSearch.stateTerritory"
                    :items="addressData.states"
                    :label="'State/Territory *'"
                    :rules="[rules.required]"
                    outlined
                    dense
                />
              </v-col>

              <v-col sm="6" cols="12">
                <v-text-field
                    v-model="advanceSearch.postcode"
                    :label="'Postcode *'"
                    outlined
                    dense
                    :rules="[rules.numeric, rules.required, rules.postcode]"
                />
              </v-col>
              <v-col sm="6" cols="12">
                <v-text-field
                    v-model="advanceSearch.country"
                    :label="'Country'"
                    outlined
                    dense
                    disabled
                />
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
      </v-tabs-items>

      <v-col cols="12" v-if="showAgreeTC">
        <v-card
            class="background_accent"
        >
          <v-card-title class="text-h5">
            NBN Provider Terms and Conditions
          </v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col cols="1">
                <v-checkbox v-model="agreeTC"/>
              </v-col>
              <v-col cols="11">
                <p>I acknowledge that the Breeze Connect NBN offering is categorized as one of the Breeze Connect Designated products and is subject to all of the Terms & Conditions in the partner agreement, with the exception of the commission structure.<br /><br />
                  I acknowledge that Breeze Connect will provide an ongoing commission of 8% for NBN services offered by Breeze Connect to our clients.</p>
                <p v-if="$store.state.user.isAdmin" style="color:red;"><v-icon style="font-size:1.1rem;color:red;">mdi-wrench</v-icon> Admin do not need to agree to terms in order to conduct a search. Admin cannot agree to terms.
                  An NBN order cannot be placed if the terms are not agreed to, whether created by Admin or other user type.
                  This message here is displayed to inform Admin that this agreement to these terms is still outstanding.
                </p>
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
      </v-col>

        <template v-slot:actions>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn v-on="on"
                       @click="qualifyAddress"
                       :disabled="disableNextButton"
                       color="primary"
                    >
                        <v-icon left>mdi-check</v-icon> Qualify
                    </v-btn>
                </template>
                <span>Qualify this address for NBN</span>
            </v-tooltip>
        </template>
      <NBNProductOrderDialog @finished="reset( )" v-model="showNBNOrderDialog" :loading="isBusy" :customer-data="customerData" :partner-data="partnerData" :reseller-data="resellerData" :address="address" :locationID="locationID" :type="type" :agreeTC="agreeTC"/>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ExistingAddressDetailsForm from "../../pieces/Forms/ExistingAddressDetailsForm";
import AddressDetailsForm from "../../pieces/Forms/AddressDetailsForm";
import NBNProductOrderDialog from "../../dialogs/NBN/NBNProductOrderDialog";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import addressDataMixin from "../../../mixins/addressDataMixin";
const StreetNumberRegex = /^(\d{1,5})([A-Z])?(?:\-(\d{1,5})([A-Z])?)?$/;
const NameRegex = /^[A-Z](?:(?:[A-Za-z -]+)?[A-Za-z])?$/;
export default {
    name: 'SiteQualificationCard',
    components: {NBNProductOrderDialog, AddressDetailsForm, ExistingAddressDetailsForm, BasicCard},
    mixins: [GlobalHelperMixin, addressDataMixin],
    props: {
      loading: {
        default: false,
      },
      /**
       * If viewing as a wholesale user  we pass the reseller details here.
       * Not that this UI can be used without a customer by a partner.
       */
     resellerData: {
        type: Object,
        default: null,
      },
      /**
       * If viewing as a partner we pass the partner details here.
       * Not that this UI can be used without a customer by a partner.
       */
      partnerData: {
        type: Object,
        default: null,
      },

      /**
       * IF viewed by a customer we pass in the detail here.
       * Meaning here are running the process for a customer.
       */
      customerData: {
        type: Object,
        default: null,
      },
    },
    data() {
        return {
          busy:false,
          customerAddressInput:null,
          partnerAddressInput:null,
          showNBNOrderDialog:false,
          partnerAgreements:null,
          agreeTC:false,
          showAgreeTC:true,
          tab:'address',
          type:'address',
          locationID:null,
          advanceSearchValid:false,
          advanceSearch:{
            lotNumber:null,
            planNumber:null,
            unitNumber:null,
            unitType:null,
            levelNumber:null,
            levelType:null,
            siteName:null,
            roadNumberStart:null,
            roadNumberFinish:null,
            roadName:null,
            roadType:null,
            townSuburb:null,
            stateTerritory:null,
            postcode:null,
            country:'Australia',
          },
          rules: {
            numeric: v => (!v || !isNaN(v)) || 'Must be whole number',
            required: v => !!v || 'Required',
            postcode: v => (!v || /^[0-7][0-9]{3}$/.test(v)) || 'Invalid Postcode',
            street_number: [
              v => (!v || !/\s+/.test(v)) || 'Cannot contain spaces',
              v => (!v || StreetNumberRegex.test(v)) || 'Invalid street number',
            ],
            street_name: [
              v => !!v || 'Required',
              v => (v != null && v.length <= 25) || 'Cannot be more than 25 characters',
              v => NameRegex.test(v) || 'Invalid street name',
            ],
            suburb: [
              v => !!v || 'Required',
              v => (v != null && v.length <= 40) || 'Cannot be more than 40 characters',
              v => NameRegex.test(v) || 'Invalid suburb name',
            ],
          },
        };
    },
  computed:{
      address( ){
        if(this.tab === 'address' || this.tab === 0) {
          if (this.customerAddressInput != null) {
            return this.customerAddressInput;
          } else if (this.partnerAddressInput != null) {
            return this.partnerAddressInput;
          } else {
            return null;
          }
        }else if(this.tab === 'location-id' || this.tab === 1){
          return null;
        }else if(this.tab === 'advanced-search' || this.tab === 2){
          return this.advanceSearch;
        }else{
          throw "Failed to find a match for tab ("+this.tab+")";
        }
      },
      isBusy( ){
        return this.loading || this.busy;
      },
    //this is used so we can trigger a watch in a newly loaded partner
      iRep( ){
        if(this.partnerData && this.partnerData.i_rep){
          return this.partnerData.i_rep;
        }
        return null;
      },
      //this is used so we can trigger a watch on a newly loaded customer
      iCustomer( ){
        if(this.customerData && this.customerData.i_customer){
          return this.customerData.i_customer;
        }
        return null;
      },
      disableNextButton( ){
        if(this.tab == 0){
          if(this.customerAddressInput == null && this.partnerAddressInput == null){
            return true;
          }
        }else if(this.tab == 1){
          if(this.locationID == null || this.locationID == ''){
            return true;
          }
        }else if(this.tab == 2){
          if(!this.advanceSearchValid){
            return true;
          }
        }

        if(!this.agreeTC && !this.$store.state.user.isAdmin){
          return true;
        }

        return false;
      }
  },
    methods: {

      setType( ){
        if(this.tab === 0 || this.tab === 'address'){
          this.type = 'address';
        }else if(this.tab === 1 || this.tab === 'location-id'){
          this.type = 'location-id';
        }else if(this.tab === 2 || this.tab === 'advanced-search'){
          this.type = 'advanced-search';
        }
      },

      qualifyAddress(){
        /**
         * Certain users must agree to the terms and conditions.
         */
        this.setType();
        if(this.$store.state.user.isPartner){
          if(this.partnerData != null){
            this.agreeToTC(this.partnerData.i_rep);
          }else if(this.customerData != null){
            this.agreeToTC(this.customerData.i_rep);
          }else{
            throw 'Miss match of information.';
          }
        }else if(this.$store.state.user.isAdmin){
          if(this.showAgreeTC && this.agreeTC){
            this.showGlobalErrorMessage('Admin users cannot agree to a users NBN terms and conditions.');
          }else {
            this.showNBNOrderDialog = true;
          }
        }else{
          throw 'Unknown user type accessing NBN search.';
        }
      },
      async getAgreement(id){
        this.busy = true;
        let response = await this.Api.send('get', 'partners/'+id+'/agreements');
        this.busy = false;
        if (response.success) {
          this.partnerAgreements = response.data;
          if(this.partnerAgreements.nbn_provider_tc_agreed_at != null){
            this.agreeTC = true;
            this.showAgreeTC = false;
          }else{
            this.showAgreeTC = true;
          }
        }
      },
      async agreeToTC(id){
        if(this.agreeTC !== true){
          this.showGlobalErrorMessage('Terms and conditions must be agreed to in order to create an NBN order.');
          return;
        }
        this.busy = true;
        let params = {
          'agreeTC':this.agreeTC
        };
        let response = await this.Api.send('post', 'partners/'+id+'/agreements/agree-nbn-provider-tc', params);
        if (response.success) {
          this.showNBNOrderDialog = true;
          this.getAgreement(id);
        }
      },
      reset( ){
        this.customerAddressInput=null,
        this.partnerAddressInput=null,
        this.showNBNOrderDialog=false,
        this.partnerAgreements=null,
        this.agreeTC=false,
        this.showAgreeTC=true,
        this.tab='address',
        this.type='address',
        this.locationID=null,
        this.advanceSearch = {
          lotNumber:null,
          planNumber:null,
          unitNumber:null,
          unitType:null,
          levelNumber:null,
          levelType:null,
          siteName:null,
          roadNumberStart:null,
          roadNumberFinish:null,
          roadName:null,
          roadType:null,
          townSuburb:null,
          stateTerritory:null,
          postcode:null,
          country:'Australia',
        };
        if(this.customerData != null && this.customerData.i_customer){
          //find out who the owner is and get their agreements
          if(this.customerData.i_rep != null){
            this.getAgreement(this.customerData.i_rep);
          }else{
            throw 'Unknown customer owner.';
          }
        }else if(this.partnerData != null && this.partnerData.i_rep){
          this.getAgreement(this.partnerData.i_rep);
        }else if(this.resellerData != null && this.resellerData.i_customer){
          throw 'Wholesale users are not yet supported.';
        }
        //else data has not been loaded. Rely on the watchers.
      }
    },
  created( ){
    this.reset();
  },
  watch: {
    iRep(value) {
      if (value != null) {
        this.reset();
      }
    },
    iCustomer(value) {
      if (value != null) {
        this.reset();
      }
    },
  },
};
</script>
