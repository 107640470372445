<template>
  <div class="container">
    <StatusMainDisplayCard
    updateCache
    />


    <v-row dense>
      <v-col cols="12" offset-xl="3" offset-lg="2" xl="6" lg="8">
        <v-card elevation="2" style="max-width:800px; margin-left:auto; margin-right:auto;">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline">Admin Note</div>
              <v-list-item-subtitle>
                The status cache is updated whenever this page is loaded.
                So if any new status are create this page should be loaded in order to refresh the cache so
                that the banner notifications are accurate.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatusMainDisplayCard from "../../../components/cards/Status/StatusMainDisplayCard.vue";
export default {
    name: 'AdminOperationalStatus',
    components: {
      StatusMainDisplayCard
    },
    props: {
      updateCache: {
        type: Boolean,
        default: false,
      },
    },
};
</script>
