<template>
    <v-tooltip bottom :disabled="hideTooltip" open-delay="200">
        <template v-slot:activator="{ on }">
            <span v-on="on">
                <span v-if="currency">$</span
                ><span v-if="currency || fixed">{{
                    value | fixedDecimals
                }}</span
                ><span v-else>{{
                    value | thousandSeparator
                }}</span>
            </span>
        </template>
        {{ value }}
    </v-tooltip>
</template>

<script>
export default {
    name: 'FormattedNumber',
    filters: {
        thousandSeparator: function (value) {
            if (!value) value = 0;
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        fixedDecimals: function (value) {
            if (!value) value = 0;
            return value
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        addGst: {
            type: Boolean,
            default: false,
        },
        hideTooltip: {
            type: Boolean,
            default: false,
        },
        currency: {
            type: Boolean,
            default: false,
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
