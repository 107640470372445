<template>
    <v-card :loading="loading" elevation="4">
        <div v-if="$slots.actions" class="float-right pa-2">
            <slot name="actions" />
        </div>
        <v-card-text v-if="title" class="pb-0">
            <p class="overline mb-2">{{ title }}</p>
        </v-card-text>
        <v-card-text v-if="$slots.content" class="pb-0 pt-1">
            <slot name="content" class="mb-2" />
        </v-card-text>
        <div v-if="loading && !noSkeleton">
            <v-skeleton-loader type="table-thead"></v-skeleton-loader>
            <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
        </div>
        <slot v-if="!loading || noSkeleton" />
        <v-divider></v-divider>
        <v-card-actions>
            <v-card-text
                class="mr-4 grey--text float-left pa-2"
                v-if="$vuetify.breakpoint.smAndUp"
            >
                <span v-if="!loading && items > 0">
                    <span v-if="selected"
                        >{{ selected }} item<span v-if="selected > 1">s</span>
                        selected</span
                    >
                    <span v-if="!selected">
                        <span v-if="$vuetify.breakpoint.smAndUp">Showing </span>
                        <FormattedNumber :value="(page - 1) * limit + 1" hide-tooltip />-<FormattedNumber :value="(page - 1) * limit + items" hide-tooltip />
                        <span v-if="total > 0"> of <FormattedNumber :value="total" hide-tooltip /></span>
                    </span>
                </span>
                <span v-if="!loading && items === 0"> No items found </span>
                <span v-if="loading">
                    <v-progress-circular
                        v-if="loading"
                        class="mr-2"
                        :width="3"
                        :size="20"
                        color="primary"
                        indeterminate
                    ></v-progress-circular
                    >Loading...
                </span>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-pagination
                flat
                class="text-center"
                v-model="currentPage"
                :length="pages"
                :total-visible="pagesVisible"
                :disabled="loading"
                @input="updatePage"
            ></v-pagination>
            <v-spacer />
            <v-card-text
                class="grey--text text-right pa-2"
                v-if="$vuetify.breakpoint.smAndUp"
            >
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" v-on="on" :disabled="loading">
                            {{ limit }}
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(number, index) in limitOptions"
                            :key="index"
                            @click="$emit('updateLimit', number)"
                        >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
              <span class="ml-2">items per page</span>
            </v-card-text>
        </v-card-actions>
        <slot name="persistent" />
    </v-card>
</template>

<script>
import FormattedNumber from '../../pieces/FormattedNumber';
export default {
    name: 'PaginationCard',
    components: { FormattedNumber },
    data: () => ({
        currentPage: 1,
        pagesVisible: 1,
    }),
    props: {
        title: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        noSkeleton: {
            type: Boolean,
            default: false,
        },
        page: {
            type: Number,
            default: 1,
        },
        data: {
            default: () => [],
        },
        limit: {
            type: Number,
            default: 10,
        },
        selected: {
            type: Number,
            default: 0,
        },
        limitOptions: {
            type: Array,
            default: () => [5, 10, 25, 50, 100],
        },
    },
    watch: {
        '$vuetify.breakpoint.smAndUp': function (data) {
            this.pagesVisible = data ? 7 : 5;
        },
        page: function (val) {
            this.currentPage = val;
        },
    },
    methods: {
        updatePage(data) {
            this.$emit('updatePage', data);
        },
        updatePagesVisible() {
            this.pagesVisible = innerWidth > 500 ? 7 : 5;
        },
    },
    computed: {
        pages: function () {
            return (this.data && this.data.hasOwnProperty('pages')) ? this.data.pages : 1;
        },
        total: function () {
            return (this.data && this.data.hasOwnProperty('count')) ? this.data.count : 0;
        },
        items: function () {
            return (this.data && this.data.hasOwnProperty('data')) ? this.data.data.length : 0;
        },
    },
    created() {
        this.currentPage = this.page;
        this.updatePagesVisible();
        addEventListener('resize', () => {
            this.updatePagesVisible();
        });
    },
};
</script>
<style scoped>
table > thead > tr > th {
    background: transparent !important;
}
</style>