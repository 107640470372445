<template>
    <v-alert key="alert" :dense="dense" :type="type" text outlined class="mb-1">
        {{ message }}
    </v-alert>
</template>

<script>
export default {
    name: 'Alert',
    props: {
        message: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'info',
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
