<template>
    <PaginationCard
        :data="data"
        :loading="loading || isLoading"
        :limit="filter.limit"
        :page="filter.page"
        v-on:updateLimit="updateLimit"
        v-on:updatePage="updatePage"
    >
        <v-simple-table>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Sale Amount</th>
                    <th>Commission %</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <v-slide-x-transition tag="tbody" hide-on-leave group>
                <tr v-if="data.data && data.data.length === 0" key="nodata">
                    <td colspan="100%" class="text-center">
                        No results found
                    </td>
                </tr>
                <tr v-for="item in data.data" :key="item.i_xdr">
                    <td><LocalDateTime :value="item.bill_time" /></td>
                    <td>
                        {{
                            item.i_dest === 41
                                ? 'Commission'
                                : item.i_dest === 15
                                ? 'Payout'
                                : 'Unknown'
                        }}
                    </td>
                    <td>
                        <span v-if="item.i_customer"
                            ><router-link
                                :to="{
                                    name: 'CustomerSummary',
                                    params: {
                                        customerId: item.i_customer,
                                    },
                                }"
                                style="cursor: pointer"
                                >{{ item.customer_name }}</router-link
                            ></span
                        >
                        <span v-if="item.CLD">{{ item.CLD }}</span>
                    </td>
                    <td>
                        <Currency
                            v-if="item.used_quantity"
                            :value="item.used_quantity"
                        />
                    </td>
                    <td>
                        <span v-if="item.charged_quantity">{{
                            item.charged_quantity
                        }}</span>
                    </td>
                    <td><Currency :value="item.charged_amount" /></td>
                </tr>
            </v-slide-x-transition>
        </v-simple-table>
      <floating-button icon="mdi-download" tooltip="Download CSV" :loading="isLoading" @click="downloadCSV( )" />
    </PaginationCard>
</template>

<script>
import PaginationCard from '../templates/PaginationCard';
import LocalDateTime from '../../pieces/LocalDateTime';
import Currency from '../../pieces/Currency';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
import FloatingButton from "../../pieces/FloatingButton";
import {mdiPhoneIncoming, mdiPhoneOutgoing} from "@mdi/js";
export default {
    name: 'PartnerCommissionHistoryCard',
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
    components: {FloatingButton, Currency, LocalDateTime, PaginationCard },
  data: () => ({
    linkBase: process.env.VUE_APP_API_BASE || '/api/',
  }),
    methods: {
        async get(id) {
            this.isLoading = true;
            const response = await this.Api.send('post',
                'partner' + (id ? 's/' + id : '') + '/commission/transactions',
                {
                    limit: this.filter.limit,
                    page: this.filter.page,
                }
            );
            if (response.success) {
                this.data = response.data;
                this.isLoading = false;
            } else {
                this.data = [];
                this.isLoading = false;
            }
        },
      downloadCSV( )
      {
        /**
         * Note see the TransactionListCard for usage with params and filters.
         * @type {string}
         */
        let id;
        if(this.$store.state.user.isPartner){
          id = this.$store.state.data.partner.i_rep;
        }else{
          id = this.$route.params.partnerId;
        }
        const url = this.linkBase + 'partners/' + id + '/commission/transactions/csv?session-token=' + this.$store.state.session.token;
        window.location.href = url;
      },
        update() {
            this.get(this.$route.params.partnerId);
        },
    },
};
</script>
