<template>
    <v-row dense>
        <v-col cols="12" v-if="busy">
            Loading...
        </v-col>
        <v-col cols="12" v-else>
            <v-radio-group v-if="!!data" v-model="address_id" class="mt-0 px-3">
                <v-radio
                    v-for="address in data"
                    :key="address.address_id"
                    :value="address.address_id"
                    class="mb-3"
                >
                    <template v-slot:label>
                        <AddressText :data="address" />
                    </template>
                </v-radio>
                <v-radio
                    :key="0"
                    :value="0"
                >
                    <template v-slot:label>
                        Enter a new address
                    </template>
                </v-radio>
            </v-radio-group>
            <v-expand-transition>
                <div v-if="!data || address_id===0">
                    <AddressDetailsForm
                        v-model="address"
                        show-search
                        hideTick
                        :showManualEntry="showManualEntry"
                    />
                </div>
            </v-expand-transition>
        </v-col>
    </v-row>
</template>

<script>
    import apiMixin from '../../../mixins/apiMixin';
    import AddressText from '../Address/AddressText';
    import AddressDetailsForm from './AddressDetailsForm';

    export default {
        name: 'ExistingAddressDetailsForm',
        components: { AddressDetailsForm, AddressText },
        mixins: [apiMixin],
        data: () => ({
            busy: false,
            data: null,
            error: false,
            address_id: null,
            address: null,
        }),
        methods: {
            async getAddresses() {
                this.busy = true;
                const response = await this.Api.send('get','customers/' + this.customerId + '/addresses');
                if (response.success) {
                    this.data = response.data;
                } else {
                    this.error = true;
                }
                this.busy = false;
            }
        },
        watch: {
            address_id(value) {
                let data = this.data.find((v) => v.address_id === value);
                this.address = !!data ? data : null;
            },
            address(value) {
                this.$emit('input', value);
            }
        },
        props: {
            customerId: {
                type: Number,
                default: null
            },
            currentAddressId:{
              type:Number,
              default:null
            },
            value: {
                type: Object,
                default: null
            },
            addresses:{
              type: Array,
              default: function () {
                return []
              }
            },
            hideTick:{
              type: Boolean,
              default: false
            },
          showManualEntry:{
              type:Boolean,
              default:true,
            },
        },
        created() {
            if (!!this.customerId) {
              if(this.addresses.length != 0){
                this.data = this.addresses;
              }else{
                this.getAddresses();
              }
            } else {
                this.error = true;
            }
        }
    };
</script>