var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasicCard',{attrs:{"loading":_vm.loading,"noPadding":"","title":_vm.statusGroupData.title}},[_c('v-row',[_c('v-col',{staticClass:"body-1 text--primary",attrs:{"cols":"12"}},_vm._l((_vm.statusGroupData.services),function(item,index){return _c('div',{key:item.name,staticClass:"pa-4 d-flex align-center justify-space-between",class:{
                      'list-item-background-dark': _vm.$vuetify.theme.dark && index % 2 !== 0,
                      'list-item-background-light': !_vm.$vuetify.theme.dark && index % 2 !== 0,
                      'list-item-dark': _vm.$vuetify.theme.dark,
                      'list-item-light': !_vm.$vuetify.theme.dark,
                  }},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:" pr-1"},[_vm._v(_vm._s(item.name))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])],1),_c('span',{staticClass:"text-right",style:({ color: '#'+_vm.colorSwtich(item.status.color) })},[_vm._v(_vm._s(item.status.name))])])}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }