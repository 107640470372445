<template>
    <v-autocomplete
        :items="data"
        :loading="loading"
        :label="label"
        :autofocus="autofocus"
        :search-input.sync="query"
        placeholder="Start typing partner name to search..."
        item-text="name"
        item-value="i_rep"
        :disabled="disabled"
        :dense="dense"
        @change="change"
        outlined
        no-filter
        hide-no-data
        clearable
        @keydown.enter.native.prevent
        return-object
    />
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'PartnerDropdown',
    mixins: [apiMixin],
    data: () => ({
        data: [],
        loading: false,
        timer: null,
        query: null,
        currentQuery: '',
        selected: null,
        noPartner: false,
    }),
    methods: {
        change: function (value) {
            this.currentQuery = value ? value.name : '';
            this.selected = value;
            this.$emit('input', value ? value.i_rep : null);
        },
        async search(query) {
            this.loading = true;
            this.currentQuery = query;
            const response = await this.Api.send('post','partners/list', {
                name: query,
            });
            if (response.success && query===this.currentQuery) {
                this.data = response.data.data;
            };
            this.loading = false;
        },
        async get(id) {
            this.loading = true;
            const response = await this.Api.send('post','partners/' + id + '/info');
            if (response.success) {
                this.selected = response.data.data;
                this.currentQuery = response.data.data.name;
            } else {
                this.$emit('input', null);
            }
            this.loading = false;
        },
    },
    watch: {
        'formData.valid': function (value) {
            this.$emit('update', value ? this.formData : null);
        },
        query(val) {
            clearTimeout(this.timer);
            if (val) {
                if (!this.selected || this.selected.name !== val) {
                    this.timer = setTimeout(() => {
                        if (val !== this.currentQuery) {
                            this.search(val);
                        }
                    }, 500);
                }
            } else {
                this.data = [];
                this.selected = null;
                this.currentQuery = '';
            }
        },
        disabled(value) {
            if (value && this.selected) this.$emit('input', null);
        },
        value(value) {
            if (value) {
                if (value!==this.value)
                    this.get(value);
            } else {
                this.data = null;
                this.selected = null;
                this.query = '';
            }
        }
    },
    created() {
        if (this.value) {
            this.get(this.value);
        }
    },
    props: {
        label: {
            type: String,
            default: 'Partner',
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Number,
            default: null,
        },
    },
};
</script>
